.template_wrapper{
    display: flex;
    flex-direction: column;
    gap: 50px;

    .row{
        .header_item{
            display: flex;
            // align-items: center;
            justify-content: flex-start;
            gap: 15px;
            h3{
                font-size: 20px;
                margin-bottom: 20px;
            }
            .label{
                font-weight: 500;
                white-space: nowrap;
            }
            .label_active{
                color: #FF6600;
                text-shadow: green 1px 0 10px;
            }
            svg{
                cursor: pointer;
            }
            .header_item_left, .header_item_right{
                display: flex;
                gap: 10px;
                align-items: center;
                flex: 1;
            }
            .header_item_right{
                padding-right: 40px;
                flex: 1;
                display: flex;
                justify-content: center;
                border-left: 1px #CCC solid;
            }
        }
        .condition_wrapper{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-bottom: 30px;
            .content{
                width: 100%;
                display: flex;
                gap: 20px;
                .left{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    background-color: rgb(250, 250, 250);
                    box-shadow: 0 6px 26px #00000026;
                    padding: 10px;
                    border-radius: 4px;
                    min-width: 40%;
                    max-width: 40%;
                    position: relative;

                    .list_item{
                        width: 100%;
                        padding: 15px 5px 15px 15px;
                        display: flex;
                        gap: 15px;
                        background-color: #F2F2F2;
                        .list_item_left{
                            display: flex;
                            width: 100%;
                            gap: 10px;
                            .label{
                                font-weight: 600;
                            }
            
                            .list_item_values{
                                display: flex;
                                flex-wrap: wrap;
                                gap: 10px;
                                .variable{
                                    display: flex;
                                    align-items: center;
                                    gap: 3px;
                                    white-space: nowrap;
                                    span{
                                        transform: translateY(0px);
                                    }
                                }
                            }
                        }
                        .list_item_right{
                            .remove_type_wrapper{
                                width: 15px;
                                height: 15px;
                                svg{
                                    width: 15px;
                                    height: 15px;
                                }
                            }
                        }
                    }

                    .remove_wrapper{
                        position: absolute;
                        right: 10px;
                        top: 10px;
                    }
                }
                .right{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    gap: 45px;
                    .item{
                        display: flex;
                        flex-direction: column;
                        gap: 3px;
                        flex: 1;
                        textarea{
                            border-radius: 4px;
                            // box-shadow: 0 6px 26px #00000026;
                            padding: 5px;
                            border: 1px #CCC solid;
                            height: 100%;
                        }
                    }
                }
            }
            .footer{
                display: flex;
                justify-content: center;
                gap: 20px;
            }
        }
    }

    .buttons_wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
        box-shadow: 0 6px 26px rgba(0, 0, 0, 0.1490196078);
        border-radius: 8px;
        position: relative;

        .buttons_wrapper_content{
            width: 100%;
            display: flex;
            // min-height: 100px;
            .buttons_column, .buttons_column_bottom{
                flex: 1;
                max-width: 50%;
                border-right: 8px #e7e7e7 solid;
                display: flex;
                flex-direction: column;
                gap: 15px;
                padding: 15px;
                position: relative;

                .arrow_wrapper{
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    transform: rotate(-90deg);
                    svg{
                        path{
                            fill: green;
                        }
                    }
                }

                .buttons_column_title{
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: 600;
                    color: #8b8b8b;
                }
                .buttons_column_content, .buttons_column_content_bottom{
                    display: flex;
                    gap: 10px;
                    flex-wrap: wrap;
                    min-height: 80px;
                    // box-shadow: 0 6px 26px rgba(0, 0, 0, 0.1490196078);

                    .deal_button{
                        cursor: all-scroll;
                        display: flex;
                        align-items: center;
                        box-shadow: 0 6px 26px rgba(0, 0, 0, 0.1490196078);
                        height: fit-content;
                        border-radius: 3px;
                        padding: 4px 7px;
                        user-select: none;
                        .button{
                            padding: 7px 10px;
                            white-space: nowrap;
                        }
                        .info_wrapper{
                            position: relative;
                            cursor: pointer;
                            .modal_wrapper{
                                opacity: 0;
                                visibility: hidden;
                            }
                            &:hover{
                                .modal_wrapper{
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }

                    .accept{
                        color: green;
                        border: 1px rgb(159, 226, 159) solid;
                        svg{
                            width: 20px;
                            height: 20px;
                            path{
                                fill: green;
                            }
                        }
                    }

                    .cancel{
                        color: red;
                        border: 1px rgb(255, 172, 172) solid;
                        svg{
                            width: 20px;
                            height: 20px;
                            path{
                                fill: red;
                            }
                        }
                    }

                    .info{
                        color: orange;
                        border: 1px rgb(255, 210, 127) solid;
                        svg{
                            width: 20px;
                            height: 20px;
                            path{
                                fill: orange;
                            }
                        }
                    }
                }
                .buttons_column_content_bottom{
                    justify-content: center;
                }
            }
            .buttons_column_bottom{
                width: 100%;
                max-width: 100%;
            }
        }
    }
}

.modal_type{
    position: absolute;
    top: 0;
    left: 0;
    background: #FFF;
    margin: 30px !important;
    width: 100%;
    margin: 0 !important;
}

.footer{
    display: flex;
}

input{
    border: 1px #CCC solid;
    border-radius: 4px;
    padding: 5px 7px;
    width: 100%;
    border-radius: 4px
}

select{
    padding: 5px 10px;
    border-radius: 2px;
    background: #FFF;
    border: 1px #CCC solid;
    border-radius: 4px;
}