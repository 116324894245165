.moderation-payment_block-buttons_but {
  width: calc(327px);
  height: calc(40px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(102, 92, 209);
  color: rgb(255, 255, 255);
  border: 1px solid transparent;
  padding: 16px;
  font-size: calc(16px);
  font-weight: 400;
  border-radius: 4px;
  margin-bottom: 10px;

  &:disabled {
    background-color: #d4d2d5;
    color: #fff;
    pointer-events: none;
  }
  &:hover {
    background-color: rgb(16, 16, 16);
    border: 1px solid transparent;
    color: rgb(255, 255, 255);
  }
  & b {
    margin: 0 5px;
    font-weight: 900;
  }
  &:nth-child(2),
  &:nth-child(3) {
    width: calc(327px);
    height: calc(40px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    font-size: calc(16px);
    font-weight: 400;
    border-radius: 4px;
    margin-bottom: 10px;
    background-color: rgb(255, 255, 255);
    color: rgb(102, 92, 209);
    border: 1px solid rgb(102, 92, 209);
  }
  &:nth-child(2):disabled {
    border-color: #d4d2d5;
    color: #d4d2d5;
    pointer-events: none;
  }
  &:nth-child(2):hover,
  &:nth-child(3):hover {
    background-color: rgb(16, 16, 16);
    border: 1px solid transparent;
    color: rgb(255, 255, 255);
  }
}
.moderation-payment_filter {
  display: flex;
}
