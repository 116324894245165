@import "../../../utils/styles/_variables.scss";

.header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .button_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .add_button {
      width: auto;
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    .left_block {
      display: flex;
      flex-direction: column;
      gap: 10px;
      h3 {
        font-size: 18px;
      }
      .row {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        padding: 6px 0;

        .filter_text {
          font-size: 16px;
          cursor: pointer;
        }
        img {
          cursor: pointer;
        }
      }
    }
    .right_block {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 5px;
    }
  }
  .filters_wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: hidden;
    height: 0;
    transition: 0.5s;
    padding: 0 2px;

    .titles {
      display: flex;
      gap: 12px;
      p {
        width: fit-content;
        color: #707075;
        span {
          color: #000;
        }
      }
      .clear_filters {
        color: #FF6600;
        cursor: pointer;
      }
    }
    .filters {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      .filter {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1 0 auto;
        span {
          font-size: 12px;
        }
        input {
          height: 44px;
          border: 1px #e5e0eb solid;
          padding: 12px;
          width: 100%;
        }
      }
    }
    .buttons_wrapper {
      display: flex;
      gap: 10px;
      button {
        margin: 0;
        white-space: nowrap;
        width: -moz-fit-content;
        width: fit-content;
        border-radius: 4px;
      }
    }
  }
}
.content_wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.footer {
  display: flex;
  justify-content: flex-end;
}
