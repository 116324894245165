.modal_wrapper{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  animation: animation-show-bg .2s forwards linear;
  z-index: 8;
}
.modal{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  //box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  max-width: 735px;
  width: 100%;
  animation: animation-show-modal .2s forwards linear;
  border-radius: 8px;
  z-index: 9;
  header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px #E0E3E4 solid;
    padding: 13px 24px;
    h3{
      margin: 0;
      font-size: 20px;
    }
    .close_btn{
      cursor: pointer;
      font-size: 18px;
    }
  }
  .content{
    padding: 13px 24px;
    display: flex;
    flex-direction: column;
    .title{
        margin-bottom: 5px;
    }

    .row{
      margin-top: 17px;
      display: flex;
      button{
        white-space: nowrap;
        width: fit-content;
        border-radius: 4px;
      }
    }
  }
}

.close_wrapper{
  animation: animation-hide-bg .2s forwards linear;
}
.close_modal{
  animation: animation-hide-modal .2s forwards linear;
}

@keyframes animation-show-modal {
  0%{
    transform: translate(-50%, -70%);
    opacity: 0;
  }
  100%{
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes animation-show-bg {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 0.5;
  }
}

@keyframes animation-hide-bg {
  0%{
    opacity: 0.5;
  }
  100%{
    opacity: 0;
  }
}

@keyframes animation-hide-modal {
  0%{
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  100%{
    transform: translate(-50%, -70%);
    opacity: 0;
  }
}

