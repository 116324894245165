.modal_wrapper{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 6px 26px #00000026;
    background-color: #fff;
    padding: 15px;
    border-radius: 6px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 500px;
    min-width: 400px;
    
    .line{
        width: 100%;
        background-color: #e6e6e6;
        height: 10px;
        padding: 0;
    }

    .message_wrapper{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .header{
            color: #585858;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .recipient_wrapper{
                display: flex;
                gap: 7px;
                .recipient{
                    color: #FF6600;
                }
            }
        }
        .conditions_wrapper{
            display: flex;
            flex-direction: column;
            padding: 5px;
            gap: 3px;
            background: #F2F2F2;
            .condition_item{
                display: flex;
                gap: 5px;
                .condition_title{
                    color: #585858;
                    padding: 0;
                }
                .condition_variables{
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    .condition_variable{
                        padding: 0;
                        color: #585858;
                        .galka{
                            padding: 0;
                            color: green;
                        }
                    }
                }
            }
        }
        .buttons_wrapper{
            display: flex;
            width: 100%;
            .left, .right{
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 50%;
                .title{
                    color: #585858;
                    text-align: center;
                }
                .buttons{
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    .deal_button{
                        display: flex;
                        gap: 4px;
                        align-items: center;
                        box-shadow: 0 6px 26px rgba(0, 0, 0, 0.1490196078);
                        height: fit-content;
                        padding: 4px 6px;
                        border-radius: 3px;
                        width: fit-content;
                        user-select: none;
                        .button{
                            // padding: 7px 10px;
                            white-space: nowrap;
                            font-size: 12px;
                        }
                        .info_wrapper{
                            position: relative;
                            cursor: pointer;
                            .modal_wrapper{
                                opacity: 0;
                                visibility: hidden;
                            }
                            &:hover{
                                .modal_wrapper{
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }
                    .accept{
                        color: green;
                        border: 1px rgb(159, 226, 159) solid;
                        svg{
                            width: 15px;
                            height: 15px;
                            path{
                                fill: green;
                            }
                        }
                    }

                    .cancel{
                        color: red;
                        border: 1px rgb(255, 172, 172) solid;
                        svg{
                            width: 15px;
                            height: 15px;
                            path{
                                fill: red;
                            }
                        }
                    }

                    .info{
                        color: orange;
                        border: 1px rgb(255, 210, 127) solid;
                        svg{
                            width: 15px;
                            height: 15px;
                            path{
                                fill: orange;
                            }
                        }
                    }
                }
            }
        }
    }
}