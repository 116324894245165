.table{
    width: 100%;
    border-collapse: collapse;

    thead{
        border-top: 1px solid #c2c2c2;
        border-bottom: 1px solid #c2c2c2;
    }

    td{
        width: 12%;
        padding: 12px 5px;

        .button_wrapper{
                width: 22px;
                height: 22px;
                cursor: pointer;
            svg{
                width: 22px;
                height: 22px;
            }
        }
    }

    .checkbox_td{
        width: 3%;
    }

    .company_td{
        width: 40%;
    }

    .menu_td{
        width: 5%;
    }
    
    .company{
        
        &:hover{
            box-shadow: 0px 0px 10px 0px #00000026;
        }

        .company_image_wrapper{
            display: flex;
            gap: 10px;
            
        }

        .company_wrapper{
            display: flex;
            gap: 10px;

            .company_image{
                position: relative;
                width: 128px;
                height: 104px;
    
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
    
            .company_content{
                display: flex;
                flex-direction: column;
                gap: 20px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 400px;
                padding: 20px 0px;
    
                .company_name{
                    font-weight: 600;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .company_region{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .td_wrapper{
            padding: 20px 0px;
            height: 100%;

            .amount{
                color: #FF6600;
                font-weight: 500;
            }

            .status_active {
                font-size: 13px;
                padding: 4px 8px;
                background: #00a046;
                color: #fff;
                border-radius: 2px;
           }
            .status_moderation {
                font-size: 13px;
                padding: 4px 8px;
                background: #0083c3;
                color: #fff;
                border-radius: 2px;
           }
            .status_reject {
                font-size: 13px;
                padding: 4px 8px;
                background: #ff4838;
                color: #fff;
                border-radius: 2px;
           }
            .status_completed {
                font-size: 13px;
                padding: 4px 8px;
                background: #38c63d;
                color: #fff;
                border-radius: 2px;
           }
            .status_suspended {
                font-size: 13px;
                padding: 4px 8px;
                background: #e7be2f;
                color: #fff;
                border-radius: 2px;
           }
        }
    }
}