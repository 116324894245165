.tabs_wrapper{
    display: flex;
    position: relative;
    border-bottom: 1px #E0E3E4 solid;

    .item{
        padding: 15px 16px;
        user-select: none;
        cursor: pointer;
        color: #5A5A5A;
        display: flex;
        gap: 5px;
        align-items: center;

        .dot{
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #FF6600;
        }
    }

    .item_active{
        color: inherit;
    }

    .line{
        position: absolute;
        bottom: -1px;
        width: 10%;
        height: 3px;
        background: #FF6600;
        border-radius: 3px;
        transition: .3s;
    }
}