.profile_wrapper{
    display: flex;
    flex-direction: column;
    gap: 35px;

    .img_wrapper{
        flex: 1 0 auto;
        width: 192px;
        height: 108px;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .left{
        min-width: 500px;
        max-width: 700px;
        display: flex;
        flex-direction: column;
        gap: 26px;

        .header{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .inputs_wrapper{
            display: flex;
            flex-direction: column;
            gap: 26px;
    
            .row{
                display: flex;
                align-items: center;
                gap: 7px;

                .input_wrapper{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 15px;
                
                    .input{
                        border: 1px #CCC solid;
                        padding: 8px 12px;
                        border-radius: 4px;
                        min-width: 300px;
                    }
                }
            }
        }
    }
    .footer{
        display: flex;
        gap: 15px;

        .success_button{
            padding: 8px 28px;
            background: #FF6600;
            color: #FFF;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 5px;

            svg{
                width: 24px;
                height: 24px;
            }

            span{
                cursor: pointer;
            }
        }

        .reject_button{
            background: none;
            color: #FF6600;
            border: 1px #FF6600 solid;

            svg{
                width: 16px;
                height: 16px;
            }
        }

        
    }
}
.buttons_wrapper{
    display: flex;
}