.header{
    display: flex;
    gap: 20px;

    .header{
        padding: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
        position: relative;

        .img_wrapper{
            width: 192px;
            height: 108px;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .footer{
            display: flex;
            gap: 15px;
            flex: 1 0 auto;
            align-items: flex-end;

            .success_button{
                padding: 8px 28px;
                background: #FF6600;
                color: #FFF;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 5px;
                height: 40px;

                svg{
                    width: 24px;
                    height: 24px;
                }

                span{
                    cursor: pointer;
                }
            }

            .reject_button{
                background: none;
                color: #FF6600;
                border: 1px #FF6600 solid;
                height: 40px;

                svg{
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .moderation_status{
            display: flex;
            gap: 7px;
            padding: 7px 7px;
            box-shadow: 0 0 35px #3e134d26;
            position: absolute;
            top: 10px;
            right: 15px;
            color: #FFF;
        }

        .moderation{
            background: #505050;
        }
        .active{
            cursor: pointer;
           
            background: #00a046;
        }
        .rejected{
            cursor: pointer;
            
            background: #FF1500;
        }
        .payment{
            
            background: #0083c3;
        }
        .suspended{
            
            background: #ff791f;
        }
    }
}

.banners_wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
    position: relative;

    .langs_buttons{
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        gap: 15px;

        .lang_item{
            padding: 5px;
            border-radius: 3px;
            border: 1px #f60 solid;
            color: #f60;
            text-transform: uppercase;
            font-size: 14px;
            cursor: pointer;
            user-select: none;
        }

        .active{
            background: linear-gradient(175deg, #FF6600 25.72%, #D128A1 75.91%);
            color: #FFF;
        }
    }

    .banners{
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 40px;

        .banner{
            display: flex;
            width: calc(50% - 10px);
            border-radius: 4px;

            .img_wrapper{
                width: 192px;
                height: 108px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .center{
                padding: 12px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }
}

.input_row{
    display: flex;
    gap: 20px;
    .select_wrapper{
        width: calc(50% - 10px);
        display: flex;
        flex-direction: column;
        gap: 6px;

        .select{
            position: relative;
        }

        .title{
            font-size: 12px;
            margin-left: 3px;
        }

        .checkboxes_wrapper{
            display: flex;
            gap: 15px;
            padding: 8px;

            .checkbox_wrapper{
                display: flex;
                gap: 5px;
    
                .label{
                    font-size: 14px;
                }
            }
        }

        .row{
            display: flex;
            gap: 15px;

            input{
                border: 1px #FFE7D7 solid;
            }
        }
    }
}

.input_row_half{
    width: calc(50% - 10px);
    display: flex;
    align-items: flex-end;

    .select_wrapper{
        width: 100%;
    }
    .label{
        padding: 8px 12px;
        border: 1px #FFE7D7 solid;
        border-radius: 4px;
        height: fit-content;
    position: relative;
        span{
            font-size: 14px;
            white-space: nowrap;
        }
    }
}

.price_row{
    display: flex;
    border: 1px #ABA7AF solid;

    .left{
        border-right: 1px #EBEBEB solid;
    }
    .price_block{
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 26px;
        padding: 20px;

        .price_item{
            display: flex;
            flex-direction: column;
            gap: 6px;

            .price{
                color: #FF6600;
            }
        }
    }
}

.buttons_row{
    display: flex;
    gap: 20px;
}


.modal_wrapper{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1200px;
}

.box_shadow{
    box-shadow: 0 0 35px #3e134d26;
    padding: 15px;
}

.input_wrapper{
    display: flex;
    flex-direction: column;
    gap: 15px;

    .input{
        border: 1px #CCC solid;
        padding: 8px 12px;
        width: 100%;
        border-radius: 4px;
    }
}


.input_list_wrapper {
    padding: 4px 10px 4px 4px;
    border: 1px #ffe7d7 solid;
    border-radius: 4px;
    display: flex;
    gap: 7px;
    min-height: 32px;
}
.input_list_wrapper .items {
    display: flex;
    gap: 7px;
    flex-wrap: wrap;
    width: 100%;
}
.input_list_wrapper .items .list_item {
    font-size: 14px;
    padding: 4px 8px;
    background: #ebebeb;
    border-radius: 2px;
    display: flex;
    gap: 10px;
    align-items: center;
}
.input_list_wrapper .items .list_item .delete {
    width: 13px;
    height: 13px;
    cursor: pointer;
}
.input_list_wrapper .items .list_item .delete svg {
    position: static;
    width: 13px;
    height: 13px;
    transform: translate(0, 0);
}
.input_list_wrapper .buttons {
    display: flex;
    gap: 5px;
}
.input_list_wrapper .buttons .remove_icon {
    width: 16px;
    height: 16px;
    position: relative;
    cursor: pointer;
}
.input_list_wrapper .buttons .remove_icon svg {
    position: static;
    transform: translate(0, 0);
}