@import "../_variables";

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  &-wallpaper {
    width: 50%;
    height: 100vh;
    background-color: #FF6600;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @include tablet {
      display: none;
    }
  }
  &-wrapper {
    width: 50%;
    padding: 15px;

    @include tablet {
      width: 100%;
    }
  }
  &-container {
    max-width: 492px;
    margin: 0 auto;
    @include centering;
    flex-direction: column;
    &_logo{
      max-width: 180px;
    }
    &_title {
      margin-top: 147px;
      margin-bottom: 40px;
      font-weight: 700;
      font-size: 36px;
      line-height: 49px;
      text-align: center;

      @include tablet {
        font-size: 32px;
        line-height: 43px;
        margin-top: 100px;
      }

      @include mobile {
        font-size: 24px;
        line-height: 37x;
        margin-top: 60px;
      }
    }
    &_form {
      width: 100%;
      &-subtitle {
        text-align: center;
        margin-bottom: 40px;
        font-weight: 700;
        font-size: 36px;
        line-height: 49px;

        text-align: center;

        @include tablet {
          font-size: 32px;
          line-height: 43px;
        }

        @include mobile {
          font-size: 24px;
          line-height: 37x;
        }
      }
      &-inputs {
        width: 100%;
        margin-top: 30px;
        &_inp {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
        }
        &_label {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 148%;
        }
        &_login,
        &_password {
          width: 100%;
          height: 44px;
          border: 1px solid $basic-color-borderInp;
          padding: 12px;
          border-radius: $basic-color-borderInpRad;
          font-size: 14px;
          font-weight: 400;
          line-height: 140%;
          margin-bottom: 25px;
          &::placeholder {
            color: $basic-color-borderInpPlaceholder;
            margin: 0;
            padding: 0;
          }
          &_password {
            width: 100%;
          }
          &-wrapper {
            width: 100%;
            position: relative;
          }
          &-eye {
            position: absolute;
            right: 14px;
            top: 20px;
            cursor: pointer;
          }
        }
      }
      &-button {
        @include buttonCustom(228, 48, 18);
        margin: 0 auto;
      }
    }
  }
}
