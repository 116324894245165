@import "_variables";

@import "components/_header";
@import "components/_sidebare";
@import "components/_footer";
@import "components/_alertModal";
@import "components/_changeUnitForPrice";
@import "components/_htmls";

@import "pages/_login-page";
@import "pages/_terms-page";
@import "pages/_templateContent";
@import "pages/_users";
@import "pages/_noticeItem";
@import "pages/_template";
@import "pages/finances-page";
@import "pages/_rbacPage";
@import "pages/_faqPage";
@import "pages/_categoriesPage";
@import "pages/_statistic";
@import "pages/_subscriptionPage";
@import "pages/_advertising";
@import "pages/_notificationsPage";
@import "pages/_pollPage";
@import "pages/_moderationPayment";

@import "bootstrap/dist/css/bootstrap.min.css";

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;500;700;900&family=Roboto:wght@100;400;500;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;500;700;900&family=Poppins:wght@200;300;400;500;700;900&family=Roboto:wght@100;400;500;900&display=swap");

* {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
  transition: 0.1s forward;
}
*:before *:after {
  box-sizing: border-box;
}
*:disabled {
  opacity: 0.5 !important;
  background-color: rgb(233, 236, 239);
}
* ul {
  padding: 0;
  margin: 0;
}
a:focus,
a:active {
  outline: none;
}
nav,
footer,
header,
aside {
  display: block;
}
html,
body {
  height: 100%;
  width: 100%;
  line-height: 1;
  font-size: 14px;
  font-family: $font-fontDefault-Nunito;
  margin: 0 auto;
  outline: none;
  scroll-behavior: smooth !important;
  background: $basicBG;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $basic-color-text-title;
  min-width: 1440px;
}

.scrolling-blocked {
  overflow: hidden;
}

input::-ms-clear {
  display: none;
}
input {
  &::-webkit-input-placeholder {
    color: grey;
    padding: 10px;
    text-overflow: ellipsis;
  }
  &::-moz-placeholder {
    color: grey;
    padding: 10px;
    text-overflow: ellipsis;
  }
  &:-ms-input-placeholder {
    color: grey;
    padding: 10px;
    text-overflow: ellipsis;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
}
button:active,
button:focus {
  outline: none !important;
}
button::-moz-focus-inner {
  border: 0 !important;
}
button {
  cursor: pointer;

  span {
    cursor: pointer;
  }
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  outline: none !important;
}
video:active,
video:focus {
  outline: none !important;
}
ul,
li * {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav * {
  list-style-type: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  cursor: default;
  font-weight: bold;
}
p,
span {
  cursor: default;
  margin-bottom: 0;
}
html:not(.touch-screen)::-webkit-scrollbar-track,
html:not(.touch-screen) *::-webkit-scrollbar-track {
  background: $white;
  border-radius: 6px;
  width: 10px;
}
html:not(.touch-screen)::-webkit-scrollbar-thumb,
html:not(.touch-screen) *::-webkit-scrollbar-thumb {
  //background: $basic-color-text-title
  background: rgba(210, 210, 210, 1);
  border-radius: 6px;
  width: 10px;
}
html:not(.touch-screen)::-webkit-scrollbar,
html:not(.touch-screen) *::-webkit-scrollbar {
  width: 5px;
  border-radius: 6px;
  height: 5px;
}
#root {
  width: 100%;
  height: inherit;
}
.App {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}
.appWrapper {
  width: inherit;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.contentBox {
  width: 100%;
  height: max-content;
  position: relative;
  overflow: hidden;
}
.loader-button {
  animation: 1s linear infinite animationLoader;
}
.loader-content {
  margin: 0 auto;
  @include centering;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &_svg {
    animation: 1s linear infinite animationLoader;
    background-color: transparent !important;
  }
}
@keyframes animationLoader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table-responsive {
  overflow: auto;
} //height: 70vh
.tox-split-button {
  .tox-split-button__chevron {
    display: none !important;
  }
}

.recharts-cartesian-axis-tick-value {
  tspan {
    color: red !important;
  }
}

.app_loader{
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    path{
      fill: #FF6600 !important;
    }
  }
}

.info_modal_table_template_content{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background-color: #fff;
  box-shadow: 0 0 100px 10px #CCC;
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  max-width: 50%;
  justify-content: center;
  gap: 5px;
  border: 2px #FF6600 solid;
  padding: 6px;
  border-radius: 7px;
  box-shadow: 0 0 16px #3e134d26;
}

.info_modal_table_template_content_title{
  color: #FF6600;
}

.info_modal_table_template_content_items{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.info_modal_table_template_content_item{
  border-bottom: 1px #CCC solid;
  font-size: 12px;
}
.terms-wrapper_tabl{
  overflow: hidden;
}

.absolute_button{
  position: absolute;
  top: 20px;
  right: 20px;
}
.sync_button_wrapper{
  position: absolute;
  top: 10px;
  right: 10px;
}
.btn-primary{
    border: none;
}
