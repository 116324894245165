.modal_type{
    position: absolute;
    top: 0;
    left: 0;
    background: #FFF;
    margin: 30px !important;
    width: 100%;
}
.page_item{
    border-bottom: 1px #CCC solid;
    padding: 30px 0;   
}
.last_page_item{
    border-bottom: none;
}
.footer{
    display: flex;
}