.tabs_wrapper{
    display: flex;
    span{
        font-size: 18px;
        padding: 15px 24px;
        cursor: pointer;
        transition: .2s;
        user-select: none;
        text-transform: uppercase;
    }
}