@import "../_variables";

.notification {
  &-email {
    // max-width: 687px;
    .categories-create_block-buttons {
      justify-content: flex-end !important;
    }
    &_content {
      .noticeItem-wrapper_status-alert {
        margin-top: 20px;
      }
      .add-edit-htmls_center {
        margin: 8px 0;
      }
      .add-edit-htmls_header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        span {
          color: #FF6600;
          cursor: pointer;
        }
      }
      input,
      textarea {
        &::placeholder {
          color: $basic-color-borderInpPlaceholder;
        }
      }
      &-inputs {
        textarea {
          border: 1px #ccc solid;
        }
        p {
          margin: 10px 0px 5px 0px;
        }
        &_block {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 10px;
          .add-edit-htmls_center {
            margin: 8px 0;
            &:first-child {
              margin-right: 20px;
            }
          }
        }
      }
      &-textarea {
        margin-top: 20px;
      }
      &-deliveryTime {
        .template-content_typeBlock-label {
          margin-bottom: 20px !important;
        }
        &_label {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 20px;
        }
        &_radio,
        &_checkbox {
          margin-right: 5px;
          input[type="checkbox"]:checked {
            background-color: $basic-color-violet;
            border: 1px solid $white;
            border-radius: 50%;
            --bs-form-check-bg-image: none;
          }
          .form-check-input:checked {
            background-color: $basic-color-violet;
            border: 1px solid $white;
            border-radius: 50%;
            --bs-form-check-bg-image: none;
          }
        }
        &_datepicekr {
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          .react-datepicker__input-container {
            border: 1px solid $basic-color-borderInpPlaceholder;
            border-radius: 4px;
            padding: 8px 12px;
            width: 220px;
            input {
              border: none;
              outline: none !important;
            }
          }
          .react-datepicker-wrapper {
            margin: 0 10px 0 0;
          }
        }
      }
    }
  }
  &-viber {
    &_counter {
      &-wrapper {
        display: flex;
        align-items: center;
        &_block {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border: 1px solid $basic-color-borderInpPlaceholder;
          padding: 0 12px;
        }
        &_minus,
        &_plus {
          background-color: transparent;
          font-size: 30px;
          color: $basic-color-violet;
        }
        &_input {
          width: 80px;
          text-align: center;
          border: none;
        }
        &_select,
        &_select2 {
          margin-left: 20px;
          width: 146px;
          &:nth-child(2) {
            width: 210px;
            margin-left: 0;
          }
        }
      }
    }
  }
}

.tox {
  .tox-silver-sink .tox-tinymce-aux,
  .tox-notification--warning,
  .tox-notifications-container {
    display: none !important;
    opacity: 0 !important;
  }
}
