.permissions_wrapper{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    width: 100%;
    .page{
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-top: 1px #CCC solid;;
        border-bottom: 1px #CCC solid;
        padding: 10px 0;
        .title{
            text-align: center;
            color: #FF6600;
            font-size: 18px;
        }
        .row{
            display: flex;
            gap: 5px;
            flex-wrap: wrap;
            .item{
                display: flex;
                align-items: center;
                padding: 5px;
                border: 1px #CCC solid;
                gap: 5px;
            }
        }
    }
}