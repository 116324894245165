.wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(15px);
    display: flex;
    justify-content: center;
    align-items: center;
    .modal{
        position: relative;
        background: #FFF;
        min-width: 900px;
        max-width: 80%;
        max-height: 80%;
        overflow: auto;
        scrollbar-width: thin;
        display: flex;
        flex-direction: column;
        gap: 10px;
        box-shadow: 0px 0px 10px 0px #CCC;
        border-radius: 8px;
        padding: 10px;
        .header{
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title{
                font-size: 20px;
                color: #FF6600;
            }
        }
        .timerange_wrapper{
            display: flex;
            padding: 3px;
            border: 1px #C4C4C4 solid;
            border-radius: 4px;
            // padding: 10px;
            .timerange{
                font-size: 14px;
                line-height: 19px;
                color: #7E7E7E;
                padding: 4px 16px;
                font-weight: 600;
                border-radius: 2px;
                transition: .2s;
                cursor: pointer;
                user-select: none;
                white-space: nowrap;
            }
            .timerange_active{
                color: #FEFEFE;
                background: #FF6600;
            }
        }
        .statistics{
            
            .graph{
                
            }
        }
        .statistic_table{
            .pagination{
                display: flex;
                justify-content: space-between;
            }
            table{
              width: 100%;
              background: #FFF;
              thead{
                border-top: 2px #CCC solid;
                border-bottom: 2px #CCC solid;
                tr{
                  td{
                    padding: 20px 20px;
                  }
                }
              }
              tbody{
                tr{
                  &:nth-child(2n){
                    background: #FBFAFC;
                  }
                  td{
                    padding: 20px 20px;
                    .shape{
                      display: block;
                      width: 15px;
                      height: 15px;
                      border-radius: 50%;
                      background-color: red;
                    }
                  }
                  .tr_names{
                    position: relative;
                    cursor: pointer;
                    display: flex;
                    &:hover{
                      .names_list{
                        opacity: 1;
                        visibility: visible;
                        z-index: 1;
                      }
                    }
                    .names_arrow{
                      transform: rotate(-90deg);
                      width: fit-content;
                      height: fit-content;
                      margin-left: 7px;
                      &::after{
                        content: "\276E";
        
                      }
                    }
        
                    .names_list{
                      max-height: 200px;
                      overflow-y: auto;
                      scrollbar-width: thin;
                      position: absolute;
                      left: 0px;
                      top: 100%;
                      background: #FFF;
                      padding: 15px;
                      border-radius: 3px;
                      box-shadow: 0px 0px 10px -4px #000;
                      display: flex;
                      flex-direction: column;
                      gap: 5px;
                      visibility: hidden;
                      opacity: 0;
                      transition: .2s;
                    }
                    .show_list{
                      visibility: visible;
                      opacity: 1;
                    }
                  }
                  .td_menu{
                    width: fit-content;
                    position: relative;
                    .dots_wrapper{
                      padding: 10px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: fit-content;
                      cursor: pointer;
                    }
                    .tools_modal{
                      position: absolute;
                      top: 35px;
                      right: 0;
                      display: flex;
                      flex-direction: column;
                      background-color: #fff;
                      padding: 8px 4px;
                      z-index: 5;
                      box-shadow: 0px 0px 16px 0px rgba(54, 54, 54, 0.16);
                      border-radius: 8px;
                      gap: 5px;
                      opacity: 0;
                      visibility: hidden;
                      .item{
                        transition: .3s;
                        width: 100%;
                        span{
                          padding: 8px 4px;
                          font-size: 16px;
                          width: 100%;
                          display: block;
                          cursor: pointer;
                          user-select: none;
                          &:hover{
                            background-color: #EDEBE9;
                          }
                        }
        
                      }
                      .delete{
                        color: #D51A52;
                      }
                      .modal_wrapper{
                        position: fixed;
                        left: 0;
                        top: 0;
                        width: 100vw;
                        height: 100vh;
                        z-index: -1;
                      }
                    }
                    .active{
                      opacity: 1;
                      visibility: visible;
                    }
                  }
                }
              }
            }
          }
    }
}