.page{
    display: flex;
    flex-direction: column;
    gap: 20px;

    .content{
        display: flex;
        gap: 20px;
    
        .left{
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 450px;
    
            .user_block{
                display: flex;
                flex-direction: column;
                gap: 10px;
                background: #FFF3EB;
                padding: 20px;
                border-radius: 8px;
                box-shadow: 0px 2px 8px 0px #0000000F;
    
                .autor{
                    font-size: 12px;
                }
    
                .user_content{
                    display: flex;
                    gap: 10px;
    
                    .avatar_wraper{
                        width: 64px;
                        height: 64px;
                        border-radius: 50%;
                        position: relative;
    
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                    }
    
                    .user_info{
                        display: flex;
                        flex-direction: column;
                        flex: 1 0 auto;
    
                        .name_wrapper{
                            font-size: 20px;
                            display: flex;
                            justify-content: space-between;
                            margin-top: 10px;
                        }
    
                        .working_title{
                            font-size: 14px;
                            margin-top: 10px;
                        }
    
                        .working_value{
                            font-size: 14px;
                            color: #666666;
                        }
    
                        .rating_title{
                            font-size: 14px;
                            margin-top: 10px;
                        }
    
                        .rating_value{
                            font-size: 14px;
                            color: #666666;
                            display: flex;
                            align-items: center;
                            gap: 5px;
    
                            svg{
                                width: 25px;
                                height: 25px;
                            }
                        }
                    }
                }
    
                .user_button{
                    background: #FF6600;
                    border-radius: 4px;
                    padding: 8px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
    
                    span{
                        cursor: pointer;
                        color: #FFF;
                    }
                }
            }
        }
    
        .right{
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 800px;
            position: relative;

            .top_button{
                position: absolute;
                bottom: 100%;
                right: 0;
            }
        }
    
        .block{
            padding: 20px;
            box-shadow: 0px 2px 8px 0px #0000000F;
            display: flex;
            flex-direction: column;
            gap: 15px;
    
            .buttons_wrapper{
                width: 100%;
                margin-top: 10px;
                display: flex;
                gap: 15px;
                margin-bottom: 20px;
            
                    .success_button{
                        padding: 8px 28px;
                        background: #FF6600;
                        color: #FFF;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        gap: 5px;
            
                        svg{
                            width: 24px;
                            height: 24px;
                        }
            
                        span{
                            cursor: pointer;
                            white-space: nowrap;
                        }
                    }
            
                    .reject_button{
                        background: none;
                        color: #FF6600;
                        border: 1px #FF6600 solid;
            
                        svg{
                            width: 16px;
                            height: 16px;
                        }
                    }
            
                    .block_button{
                        background: none;
                        color: #c00000;
                        border: 1px #c00000 solid;
            
                        svg{
                            width: 16px;
                            height: 16px;
                            path{
                                fill: #c00000;
                            }
                        }
                    }
            }
    
            .slider_wrapper{
                margin: 20px 0px;
            }
    
            .block_title{
                font-size: 12px;
                font-weight: 600;
            }
    
            .block_static_item{
                display: flex;
                gap: 4px;
                flex-direction: column;
    
                .title{
                    font-size: 12px;
                }
    
                .value{
                    font-size: 14px;
                    padding: 12px;
                    width: 100%;
                    border-radius: 2px;
                    border: 1px solid #EBEBEB;
                    line-height: 19px;
                }
            }
    
            .block_item{
                display: flex;
                gap: 4px;
                flex-direction: column;
                width: fit-content;
    
                .title{
                    font-size: 12px;
                }
    
                .value{
                    padding: 4px 12px;
                    width: 100%;
                    border-radius: 2px;
                    border: 1px solid #EBEBEB;
                    display: flex;
                    gap: 8px;
                    flex-wrap: wrap;
                    width: fit-content;
    
                    span{
                        padding: 6px 8px;
                        background: #EBEBEB;
                        width: fit-content;
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        font-size: 14px;
                        line-height: 19px;
                    }
                }
    
                .boolean_value{
                    transform: translateY(-2px);
                }
            }
            .block_wrapper{
                display: flex;
                flex-direction: column;
                gap: 10px;
                border-radius: 2px;
                border: 1px solid #EBEBEB;
                padding: 10px;
    
                .title{
                    font-size: 12px;
                    font-weight: 600;
                }
    
                .block_wrapper{
                    display: flex;
                    flex-direction: column;
    
                    .discount_title{
                        font-size: 12px;
                    }
                    .discount_value{
                        padding: 4px 12px;
                    width: 100%;
                    border-radius: 2px;
                    border: 1px solid #EBEBEB;
                    display: flex;
                    gap: 8px;
                    flex-wrap: wrap;
    
                    span{
                        padding: 6px 0px;
                        width: fit-content;
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        font-size: 14px;
                        line-height: 19px;
                    }
                    }
                }
            }
        }
    }

    .bottom_button{
        display: flex;
        justify-content: center;
    }
}