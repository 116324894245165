.list_wrapper{
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  position: relative;
  .menu_icon{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    img{
      pointer-events: none;
    }
  }
  .tools_modal{
    position: absolute;
    top: 35px;
    right: 0;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 8px 4px;
    z-index: 5;
    box-shadow: 0px 0px 16px 0px rgba(54, 54, 54, 0.16);
    border-radius: 8px;
    gap: 5px;
    opacity: 0;
    visibility: hidden;
    .item{
      transition: .3s;
      width: 100%;
      span{
        padding: 8px 4px;
        font-size: 16px;
        width: 100%;
        display: block;
        cursor: pointer;
        user-select: none;
        &:hover{
          background-color: #EDEBE9;
        }
      }
      &:nth-child(2){
        border-bottom: 1px #ABA7AF solid;
      }

    }
    .delete{
      color: #D51A52;
    }
    .modal_wrapper{
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      z-index: -1;
    }
  }
  .active{
    opacity: 1;
    visibility: visible;
  }
  .left{
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    gap: 7px;
    .date{
      color: #999999;
      font-size: 12px;
      .validation{
        font-size: 12px;
        margin-left: 20px;
        color: #ff6d6d;
        svg{
          transform: translateY(-2px);
        }
      }
      .validation_link{
        color:#FF6600;
        cursor: pointer;
      }
    }
    .name{
      color: #FF6600;
      font-size: 32px;
      cursor: pointer;
      h1{
        font-weight: 500;
        font-family: "Poppins", sans-serif;
        width: fit-content;
        cursor: pointer;
      }
    }
    .contacts{
      color: #666666;
      font-size: 12px;
    }
  }
  .block{
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
    h1{
      width: fit-content;
      color: #FF6600;
      font-size: 32px;
      font-family: "Poppins", sans-serif;
    }
    span{
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      color: #666666;
    }
  }
}