.profile_wrapper{
    display: flex;
    gap: 30px;
    flex-wrap: wrap;

    .block{
        display: flex;
        flex-direction: column;
        gap: 26px;
        border-radius: 10px;
        box-shadow: 0px -6px 25.8px 0px rgba(0, 0, 0, 0.1);
        padding: 15px 25px;
        width: calc(50% - 15px);

        .row{
            display: flex;
            align-items: center;
            gap: 7px;
            width: fit-content;

            .input_wrapper{
                width: 100%;
                display: flex;
                align-items: center;
                gap: 15px;

                span{
                    width: 200px;
                    display: block;
                    word-break: break-all;
                }
            
                .input{
                    border: 1px #CCC solid;
                    padding: 8px 12px;
                    border-radius: 4px;
                    min-width: 300px;
                }
            }
        }
    }
    
    .verification_block{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 60px;
        position: relative;

        h1{
            color: #FF6600;
            position: absolute;
            left: 25px;
            top: 25px;
        }

        .left{
            display: flex;
            flex-direction: column;
            gap: 26px;
            width: fit-content
        }
        .right{
            display: flex;
            flex-direction: column;
            gap: 30px;

            .moderation_wrapper{
                display: flex;
                flex-direction: column;
                gap: 25px;
        
                .buttons_wrapper{
                    display: flex;
                    gap: 10px;
        
                    .moderation_button{
                        padding: 4px 8px;
                        border-radius: 2px;
                        width: fit-content;
                        height: fit-content;
                        user-select: none;
                        color: #FFF;
                        white-space: nowrap;
                    }
        
                    .status_button{
                        display: flex;
                        gap: 7px;
                        padding: 7px 3px;
                        box-shadow: 0 0 35px #3e134d26;
                        cursor: pointer;
                    }
        
                    .moderation{
                        background: #6B778C;
                        color: #FEFEFE;
                        cursor: pointer;
                    }
    
                    .active{
                        background: #00A046;
                        cursor: pointer;
                        color: #FFF;
                    }
    
                    .rejected{
                        background: #FF791F;
                        color: #FFF;
                        cursor: pointer;
                    }
    
                    .banned{
                        background: #FF4838;
                        color: #FFF;
                        cursor: pointer;
                    }
        
                    .name{
                        font-size: 20px;
                    }
        
                    .email{
                        font-size: 12px;
                    }
                }
        
                .message_wrapper{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    flex: 1 0 auto;
        
                    .title{
                        display: flex;
                        gap: 7px;
                        input{
                            width: fit-content;
                            height: fit-content;
                        }
                    }
                    textarea{
                        width: 100%;
                        height: 100%;
                        border-radius: 5px;
                        padding: 10px;
                        border: 1px #CCC solid;
                        outline: none;
                        min-height: 100px;
                    }
                }
        
                footer{
                    display: flex;
                    gap: 10px;
                    button{
                        margin: 0;
                        border-radius: 4px;
                    }
                }
            }

            .images_wrapper{
                position: relative;
                display: flex;
                justify-content: space-evenly;
                flex-wrap: wrap;

                .img_wrapper{
                    width: calc(33% - 10px);
                    box-shadow: 0px -6px 25.8px 0px rgba(0, 0, 0, 0.1);
                    cursor: pointer;
                    
                    img{
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .footer{
            display: flex;
            gap: 15px;

            .success_button{
                padding: 8px 28px;
                background: #FF6600;
                color: #FFF;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 5px;

                svg{
                    width: 24px;
                    height: 24px;
                }

                span{
                    cursor: pointer;
                }
            }

            .reject_button{
                background: none;
                color: #FF6600;
                border: 1px #FF6600 solid;

                svg{
                    width: 16px;
                    height: 16px;
                }
            }

            
        }
    }
}