.content{
    display: flex;
    flex-direction: column;
    gap: 30px;
    .row{
        display: flex;
        .message_wrapper{
            max-width: 50%;
            flex: 1 0 auto;
            display: flex;
            justify-content: center;
            // align-items: center;
            gap: 30px;
            .viber_image_wrapper{
                position: relative;
                img{
                    z-index: -1;
                    max-height: 70vh;
                }
                .content_wrapper{
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -48%);
                    position: absolute;
                    width: 88%;
                    height: 76%;
                    overflow-y: scroll;
                        &::-webkit-scrollbar {
                            display: none;
                        }
                        scrollbar-width: none;
                    .content{
                        display: flex;
                        flex-direction: row;
                        gap: 8px;
                        .left{
                            width: 10%;
                            margin-left: 5px;
                            flex-shrink: 0;
                            svg{
                                width: 100%;
                            }
                        }
                        .right{
                            margin-top: 4%;
                            background-color: #EFEFEF;
                            display: flex;
                            flex-direction: column;
                            gap: 7px;
                            border-radius: 10px;
                            padding: 7px;
                            height: fit-content;
                            .text{
                                white-space: pre-wrap;
                                line-height: 20px;
                                margin-bottom: 15px;
                            }
                            .image{
                                position: relative;
                                min-height: 250px;
                            }
                            .button{
                                background-color: #FF6600;
                                border-radius: 20px;
                                color: #FFF;
                                padding: 10px;
                                width: 90%;
                                margin: 0 auto;
                                display: flex;
                                justify-content: center;
                                font-weight: 600;
                                margin-top: 10px;
                            }
                            .time{
                                width: 100%;
                                display: flex;
                                justify-content: flex-end;
                                span{
                                    font-size: 12px;
                                    color: #8f8e8e;
                                }
                            }
                        }
                    }
                }
            }
        }
        .info_wrapper{
            margin: 8px 0;
            max-width: 50%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 20px;
            background-color: #F1F3F6;
            border-radius: 8px;
            flex-grow: 1;
            .item{
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 20px 12px;
                background: #FFF;
                .value{
                    font-weight: 600;
                    .green{
                        color: green;
                    }
                }
                .validation{
                    margin-left: 20px;
                    color: rgb(255, 109, 109);
                    margin: 0 auto;
                    svg{
                        transform: translateY(-2px);
                    }
                }
                .validation_link{
                color:#FF6600;
                cursor: pointer;
                }
            }
            .statistic_item{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                padding: 20px 12px;
                background: #FFF;
                .bottom{
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    width: 100%;
                    .progress_bar{
                      flex: 1 0 auto;
                    }
                    .result{
                      svg{
                        fill: #00B998;
                        width: 24px;
                        height: 24px;
                      }
                      span{
                        color: #FF69B4;
                        font-size: 18px;
                      }
                    }
                }
            }
            .row_title{
                color: #FF6600;
                font-weight: 600;
                text-align: center;
            }
            .row_title_cicle{
                color: #FF6600;
                font-weight: 600;
                text-align: center;
                margin: 20px 0;
            }
            .statistic_row{
                display: flex;
                justify-content: space-evenly;
                margin-bottom: 20px;
                .statistic_item{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 15px;
                    .statistic_value{
                        color: #FF6600;
                        font-size: 32px;
                    }
                }
                .statistic_item_clickable{
                    cursor: pointer;
                    transition: .3s;
                    border-radius: 10px;
                    span{
                        cursor: pointer;
                    }
                    &:hover{
                        background: #F1F3F6;
                    }
                }
            }
            .steps_wrapper{
                display: flex;
                gap: 15px;
                flex-wrap: wrap;
                .step{
                    padding: 4px 8px;
                    border: 1px #ccc solid;
                    font-size: 12px;
                }
                .active{
                    background-color: green;
                    color: #FFF;
                    border: 1px transparent solid;
                }
            }
        }
    }
    .table_wrapper{
        min-height: 1027px;
        position: relative;
        .tools_wrapper{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title{
                text-align: center;
                color: #FF6600;
                font-size: 20px;
            }
        }
        table{
            width: 100%;
            background: #FFF;
            thead{
                border-top: 2px #CCC solid;
                border-bottom: 2px #CCC solid;
                tr{
                    td{
                        padding: 20px 20px;
                    }
                }
            }
            tbody{
                .loader_wrapper{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg{
                        width: 40px;
                        height: 40px;
                    }
                }
                tr{
                    &:nth-child(2n){
                        background: #FBFAFC;
                    }
                    td{
                        padding: 20px 20px;
                        .shape{
                            display: block;
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                            background-color: red;
                        }
                    }
                    .tr_names{
                        position: relative;
                        cursor: pointer;
                        display: flex;
                        &:hover{
                            .names_list{
                                opacity: 1;
                                visibility: visible;
                                z-index: 1;
                            }
                        }
                        .names_arrow{
                            transform: rotate(-90deg);
                            width: fit-content;
                            height: fit-content;
                            margin-left: 7px;
                            &::after{
                                content: "\276E";
                                
                            }
                        }
    
                        .names_list{
                            max-height: 200px;
                            overflow-y: auto;
                            scrollbar-width: thin;
                            position: absolute;
                            left: 0px;
                            top: 100%;
                            background: #FFF;
                            padding: 15px;
                            border-radius: 3px;
                            box-shadow: 0px 0px 10px -4px #000;
                            display: flex;
                            flex-direction: column;
                            gap: 5px;
                            visibility: hidden;
                            opacity: 0;
                            transition: .2s;
                        }
                        .show_list{
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.loader_wrapper{
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
        width: 40px;
        height: 40px;
    }
}