.modal_wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 30;

    .modal{
        width: 90%;
        max-width: 800px;
        height: 90%;
        background: #FFF;
        box-shadow: 0 0 35px #3e134d26;
        padding: 20px;
        display: flex;
        flex-direction: column;

        .header{
            display: flex;
            justify-content: space-between;

            .title{
                font-size: 32px;
                font-weight: 700;
                color: #FF6600;
            }
        }

        .content{
            flex: 1 0 auto;
            margin-top: 15px;
            overflow-y: scroll;
            scrollbar-width: thin;
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: center;

            .message_wrapper{
                cursor: pointer;
                padding: 10px;
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                border: 1px #c9eefd solid;
                transition: .2s;
                width: 100%;

                .left{
                    display: flex;
                    border-bottom: 1px solid #a7a7a7;
                    justify-content: space-between;
                    gap: 7px;
                    align-items: center;
                    padding-bottom: 10px;

                    .checkbox_wrapper{
                        width: 20px;
                        max-width: 20px;
                        height: 20px;
                        border-radius: 4px;
                        background: #FFF;
                        border: 1px #c9eefd solid;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #c9eefd;
                        transition: .2s;
                        flex: 1 0 auto;
    
                        .checkbox{
                            opacity: 0;
                            cursor: pointer;
                        }
                    }

                    .title{
                        text-align: center;
                        opacity: 0.7;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;

                    }

                    .menu{
                        display: flex;
                        gap: 7px;

                        svg{
                            cursor: pointer;
                        }
                    }
                }

                .message{
                    cursor: pointer;
                    user-select: none;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:hover{
                    background-color: #c9eefd;
                }
            }

            .active{
                border: 1px rgba(102, 92, 209, 0.5) solid;
                background: #c9eefd;

                .left{
                    .checkbox_wrapper{
                        background: #FFF;
                        color: rgba(102, 92, 209, 1);
    
                        .checkbox{
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .footer{
            display: flex;
        }
    }
}