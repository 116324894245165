.chart_wrapper {
  background-color: #fff;
  background-repeat: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 335px;

  @media (max-width: 490px) {
    flex-direction: column;
  }
  .title {
    position: absolute;
    left: 15px;
    top: 15px;
    color: #5a5a5a;
    font-size: 20px;

    @media (max-width: 639.98px) {
      font-size: 16px;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media (max-width: 490px) {
      row-gap: 3px;
      column-gap: 10;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
    p {
      font-size: 20px;
      line-height: 32px;
      span {
        font-weight: 700;
      }
      @media (max-width: 767.98px) {
        font-size: 16px;
      }

      @media (max-width: 639.98px) {
        font-size: 14px;
      }
    }
  }
  .loading_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 30px;
      height: 30px;
    }
  }
}
