@mixin tabletMax {
  @media (max-width: 991.98px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 766.98px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 639.98px) {
    @content;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .header_statistic {
    display: flex;
    justify-content: space-between;

    @media (max-width: 824px) {
      flex-direction: column;
      row-gap: 8px;
    }

    .left {
      .statistics_groups_wrapper {
        display: flex;
        gap: 10px;
        .statistics_groups {
          padding: 20px 10px;
          background: #dedbf566;
          .statistic_group {
            padding: 15px 24px;
            font-size: 18px;
            line-height: 23px;
            color: #5a5a5a;
            cursor: pointer;
            user-select: none;
            transition: 0.2s;
            border-bottom: 3px transparent solid;

            @media (max-width: 1090px) {
              padding: 12px 16px;
              font-size: 16px;
              line-height: 21px;
            }

            @include tablet {
              padding: 6px 10px;
            }

            @media (max-width: 480px) {
              padding: 6px 8px;
              font-size: 14px;
              line-height: 17px;
            }
          }
          .active_statistic_group {
            color: #171717;
            border-bottom: 3px #FF6600 solid;
          }
        }
      }
    }
    .right {
      .timerange_wrapper {
        display: flex;
        padding: 3px;
        border: 1px #c4c4c4 solid;
        border-radius: 4px;
        margin-left: 8px;
        max-width: max-content;
        @include tabletMax {
          margin-left: 0px;
        }

        .timerange {
          font-size: 14px;
          line-height: 19px;
          color: #7e7e7e;
          padding: 4px 16px;
          font-weight: 600;
          border-radius: 2px;
          transition: 0.2s;
          cursor: pointer;
          user-select: none;

          @include tablet {
            line-height: 17px;
            font-size: 12px;
            padding: 4px 12px;
          }

          @include mobile {
            padding: 4px 8px;
          }
        }
        .timerange_active {
          color: #fefefe;
          background: #FF6600;
        }
      }
    }
  }

  .row_title {
    width: fit-content;
    position: relative;
    img {
      position: absolute;
      right: -25px;
      top: 0px;
    }
  }
  .statistic_row {
    display: flex;
    gap: 20px;
    overflow: auto;
    padding: 10px 10px;
    scrollbar-width: thin;
    transition: 0.2s;

    .block {
      width: fit-content;
      min-height: 90px;
      height: 90px;
      background-color: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 5px 0;
      cursor: pointer;
      box-sizing: border-box;
      border: 2px transparent solid;
      transition: 0.2s;
      flex: 1 0 auto;
      min-width: 260px;

      @include tablet {
        min-height: 80px;
        height: 80px;
        min-width: 180px;
      }

      @include tablet {
        min-width: 140px;
      }

      &:hover {
        border: 2px #cad1e1 solid;
      }

      .left {
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;
        padding: 0 10px;
        cursor: pointer;

        .title {
          font-weight: 700;
          font-size: 20px;
          color: #5a5a5a;
          cursor: pointer;
          user-select: none;

          @include tablet {
            font-size: 16px;
          }
        }

        .number {
          display: flex;
          align-items: flex-end;
          cursor: pointer;

          svg {
            margin-right: 6px;
            margin-bottom: 5px;
            stroke: #009b33;
          }

          .big {
            font-weight: 600;
            font-size: 32px;
            cursor: pointer;
            user-select: none;

            @include tablet {
              font-size: 24px;
            }

            .loading_text {
              font-size: 14px;
              font-weight: 400;
            }
          }

          .procent {
            font-weight: 600;
            font-size: 14px;
            color: #21d929;
            padding-bottom: 3px;
            cursor: pointer;
            margin-left: 5px;
            user-select: none;

            @include tablet {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}

.active_block {
  border: 2px #cad1e1 solid;
  box-shadow: 0 0 8px 0 #FF6600;
}
.active_dateButton {
  color: #FF6600;
  font-weight: 600;
}
