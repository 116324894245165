@import "../_variables";

.add-edit-rbac {
  &_button {
    min-width: 170px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    svg {
      width: 30px;
      height: 30px;
      fill: #fff !important;
      path {
        fill: #fff !important;
      }
    }
  }
  &_label {
    width: 100%;
    margin-top: 20px;
    position: relative;
    select {
      background-color: #fff;
      border: 1px #ccc solid;
      border-radius: 4px;
      width: 100%;
    }
  }
  &_inp {
    margin-top: 5px;
    padding: 10px;
    &:disabled {
      background-color: transparent;
    }
  }
  &_container {
    width: 100%;
    border: 1px solid $basic-color-borderInpPlaceholder;
    border-radius: 4px;
    margin: 20px 0;
    padding: 0 20px 20px 20px;

    &-block {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      flex-direction: row;
      gap: 10px;

      &_button {
        width: 125px;
        height: 28px;
        background: #FF6600;
        border-radius: 4px;
        color: $white;
        margin-left: 15px;
      }
    }
    &-button {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .add-edit-terms_close {
        padding: 8px 12px;
        margin-top: 20px;
        border-radius: 4px;
      }
    }
  }
}
.rbac {
  &-wrapper {
    background-color: $white;
    padding: 20px;

    &_groupButtons {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &_saveButton {
      margin-bottom: 20px;
    }
    &_backButton {
      width: 40px;
      margin-bottom: 20px;
      background-color: $white;
      color: $basic-color-violet;
      border: 1px solid $basic-color-violet;
      img {
        margin: 0;
      }
    }
    &_block {
      height: 65px;
      position: relative;

      &-online {
        position: absolute;
        top: 0;
        right: 0;
        border: 1px solid rgba(101, 209, 92, 1);
        color: rgba(101, 209, 92, 1);
        padding: 3px 8px;
        background-color: rgba(101, 209, 92, 0.2);
        border-radius: $border-radius;
      }
      &-message {
        position: absolute;
        bottom: 0;
        right: 0;
        margin-right: 0;
      }
    }
    &_info {
      max-width: 780px;
      padding: 20px;
      background-color: $white;
      margin-top: 20px;
      border: 1px solid rgba(0, 0, 0, 0.16);
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
      border-radius: 8px;

      &-title {
        padding-bottom: 5px;
        border-bottom: 3px solid rgb(102, 92, 209);
      }
      &-contacts {
        margin-top: 20px;

        &_li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px 0;

          &-checkbox {
            label {
              margin-left: 10px;
            }
          }
          &-button {
            padding: 6px 12px;
            border-radius: 4px;
          }
        }
      }
      &-password {
        &_wrapper {
          position: relative;
        }
        &_eye {
          top: 15px;
        }
        &_inp {
          width: 250px;
          padding: 6px 40px 6px 12px;
          margin-bottom: 0;

          &::placeholder {
            display: flex;
            align-items: center;
          }
        }
        &_button {
          padding: 6px 12px;
          border-radius: 4px;
        }
      }
    }
  }
}
