.modal_wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 200;
    width: 100%;
    height: 100%;
    overflow: scroll;
    backdrop-filter: blur(15px);
    .modal{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 200;
        width: 95%;
        height: 100%;
        overflow: scroll;
        scrollbar-width: thin;
        h1{
            display: none;
        }
    }
}