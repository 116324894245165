.table_header{
    display: flex;
    gap: 15px;
    flex-direction: column;
    
    .search_row{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .info_row{
        font-size: 14px;
        display: flex;
        gap: 10px;
    }
}

.table{
    width: 100%;
    border-collapse: collapse;

    thead{
        border-top: 1px solid #c2c2c2;
        border-bottom: 1px solid #c2c2c2;

        tr{
            font-weight: 600;
            .cursor{
                cursor: pointer;
                user-select: none;

                &::after{
                    content: url("../../../utils/icons/arrow-sorting.svg");
                    position: relative;
                    left: 8px;
                    top: 3px;
                }
            }
            td{
                padding: 10px 5px;
            }
        }
    }

    tbody{
        tr{
            border-top: 1px solid #c2c2c2;
            border-bottom: 1px solid #c2c2c2;
            opacity: 0.9;
            cursor: pointer;

            .status_active {
                    font-size: 13px;
                    padding: 4px 8px;
                    background: #00a046;
                    color: #fff;
                    border-radius: 2px;
            }
            .status_moderation {
                font-size: 13px;
                padding: 4px 8px;
                background: #0083c3;
                color: #fff;
                border-radius: 2px;
            }
            .status_reject {
                font-size: 13px;
                padding: 4px 8px;
                background: #ff4838;
                color: #fff;
                border-radius: 2px;
            }
            .status_completed {
                font-size: 13px;
                padding: 4px 8px;
                background: #38c63d;
                color: #fff;
                border-radius: 2px;
            }
            .status_suspended {
                font-size: 13px;
                padding: 4px 8px;
                background: #e7be2f;
                color: #fff;
                border-radius: 2px;
            }

            &:nth-child(odd){
                background: rgba(222, 219, 245, 0.5);
            }

            &:hover{
                opacity: 1;
            }
        }
        td{
            padding: 10px 5px;
        }

        .td_name{
            display: flex;
            gap: 4px;
            cursor: pointer;
            .warning_wrapper{

                svg{
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}