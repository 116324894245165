.modal{
    background: #FFF;
    position: relative;
    width: 404px;
    max-height: 500px;
    padding: 16px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .image_wrapper{
        width: 100%;
        height: 209px;
        border-radius: 8px;
        position: relative;

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .status_label{
            position: absolute;
            bottom: 8px;
            right: 0;
            width: 40px;
            height: 26px;
            border-radius: 8px 0px 6px 0;
            color: #FFF;
            font-size: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 2;
        }

        .label_open{
            background: #00B998;
        }
        
        .label_close{
            background: #FF4838;
        }

        .moderation_wrapper{
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            gap: 16px;

            .success_button{
                padding: 4px 8px;
                color: #FFF;
                background: #FF6600;
                border-radius: 3px;
                cursor: pointer;
            }
            .reject_button{
                padding: 4px 8px;
                color: #FFF;
                background: #9C0B0B;
                border-radius: 3px;
                cursor: pointer;
            }
        }
    }

    .scrolled_content{
        display: flex;
        flex-direction: column;
        gap: 8px;
        overflow-y: auto;
        scrollbar-width: none;

        .title{
            font-size: 16px;
            font-weight: 600;
        }

        .company_name{
            font-size: 16px;
            font-weight: 600;
            color: #FF6600;
        }

        .link{
            font-size: 14px;
            font-weight: 600;
            color: #FF6600;
        }

        .row{
            display: flex;
            gap: 5px;
            align-items: center;

            .row_icon{
                width: 19px;
                height: 22px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .row_title{
                font-size: 14px;
                color: #4D4B4B;
            }
        }
        
        .hours_row{
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            .title{
                font-size: 14px;
                font-weight: 600;
                display: flex;
                gap: 4px;
                align-items: center;

                svg{
                    width: 19px;
                    height: 22px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    stroke: #00B998;
                }
            }
            .open_title{
                color: #00B998;
            }

            .close_title{
                color: #FF4838;

                svg{
                    stroke: #FF4838;
                }
            }

            .schedule{
                display: flex;
                align-items: center;
                gap: 4px;
            }

            .value{
                font-size: 14px;
                color: #7E7E7E;
                text-align: right;

                svg{
                    width: 18px;
                    height: 18px;
                    transform: rotate(-180deg);
                    transition: all .2s ease-in-out;

                    path{
                        stroke: #7E7E7E;
                    }
                }

                .active{
                    svg{
                        transform: rotate(0deg);
                    }
                }
            }
        }

        .chedule_block{
            font-size: 14px;
            overflow: hidden;
            transition: all .2s ease-in-out;
            height: 0px;
            color: #7E7E7E;
            margin-left: 25px;
            flex-shrink: 0;

            .schedule_row{
                
                .schedule_title{
                    width: 110px;
                    height: fit-content;
                    padding: 4px 0px;
                }

                .schedule_value{
                    padding: 4px 0px;
                }
            }
        }

        .description_wrapper{
            padding: 8px;
            font-size: 14px;
            line-height: 20px;
        }
    }
}

.active{
    box-shadow: 1px 5px 17.3px 0px rgba(0, 255, 21, 0.152);
}
.unactive{
    box-shadow: 1px 5px 17.3px 0px rgba(255, 0, 0, 0.152);
}
.moderation{
    box-shadow: 1px 5px 17.3px 0px rgba(255, 174, 0, 0.152);
}


@media(max-width: 480px){
    .wrapper{
        .modal{
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            transform: translate(0%, 0%);
            border-radius: 0px;
        }
    }
}