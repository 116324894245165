@mixin tabletMax {
  @media (max-width: 991.98px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 766.98px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 639.98px) {
    @content;
  }
}

.feedback_wrapper {
  width: 100%;
  background-repeat: 8px;
  display: flex;
  position: relative;
  min-height: 335px;
  gap: 15px;
  @include tablet() {
    flex-direction: column;
  }
  .left {
    width: 32%;
    display: flex;
    flex-direction: column;
    gap: 19px;
    @include tablet {
      gap: 8px;
      max-width: 40%;
      justify-content: center;
      flex-direction: row;
    }

    @media (max-width: 480px) {
      flex-wrap: wrap;
    }
    .star_row {
      width: 100%;
      background-color: #fff;
      border-radius: 8px;
      font-size: 20px;
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      color: #5a5a5a;
      padding: 15px;
      transition: 0.2s;
      border: 3px transparent solid;
      cursor: pointer;
      user-select: none;

      @include tablet {
        font-size: 16px;
        padding: 10px;
      }
      @include mobile {
        font-size: 14px;
        padding: 8px;
      }

      @media (max-width: 480px) {
        max-width: 100px;
      }

      p,
      span {
        cursor: pointer;
      }
      span {
        color: #FF6600;
      }
    }
    .active_star_row {
      border: 3px #dedbf5 solid;
    }
  }
  .right {
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding: 15px 15px 70px 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    position: relative;
    min-height: 610px;

    .pagination {
      position: absolute;
      bottom: 0;
      left: 10px;
    }

    .loading_wrapper {
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
        height: 50px;
      }
    }
  }
}
