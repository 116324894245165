.status_wrapper{
    width: 100%;
    height: 84px;
    background-color: rgb(255, 255, 255);
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    border-radius: 4px;

    .img_wrapper{
        content: "";
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        z-index: 5;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 15px;
    }

    .title_wrapper{
        font-family: "Poppins", sans-serif;
        position: relative;

        .title{
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 5px;
        }
    }
}

.moderation{
    border-left: 6px solid #21468f4d;
    .img_wrapper{
        background-color: #21468f4d;
    }
    .title_wrapper{
        .title{
            color: #21468f;
        }
    }
}

.active{
    border-left: 6px solid #B2E2C7;
    .img_wrapper{
        background-color: #B2E2C7;
    }
    .title_wrapper{
        .title{
            color: #00A046;
        }
    }
}

.reject_moderation{
    border-left: 6px solid #ff8e004d;
    .img_wrapper{
        background-color: #ff8e004d;
    }
    .title_wrapper{
        .title{
            color: #ff8e00;
        }
    }
}

.banned{
    border-left: 6px solid #ff3c004d;
    .img_wrapper{
        background-color: #ff3c004d;
        svg{
            path{
                fill: #ff6600;
            }
        }
    }
    .title_wrapper{
        .title{
            color: #ff6600;
        }
    }
}

.deleted{
    border-left: 6px solid #ff3c004d;
    .img_wrapper{
        background-color: #ff3c004d;
        svg{
            path{
                fill: #ff3c00;
            }
        }
    }
    .title_wrapper{
        .title{
            color: #ff3c00;
        }
    }
}