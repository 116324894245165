.wrapper{
    display: flex;
    gap: 20px;
    width: 100%;

    .passport, .driverLicense{
        width: calc(50% - 10px);
        border-radius: 10px;
        box-shadow: 0px -6px 25.8px 0px rgba(0, 0, 0, 0.1);
        padding: 15px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: relative;

        .status{
            width: fit-content;
            padding: 4px 8px;
            background: #6B778C;
            border-radius: 2px;
            font-size: 13px;
            color: #FFF;
            position: absolute;
            top: 15px;
            right: 15px;
        }

        .moderation{
            background: #FF791F;
            color: #FEFEFE;
        }

        .active{
            background: #00A046;
            color: #FFF;
        }

        .rejected{
            background: #FF4838;
            color: #FFF;
        }

        .title{
            font-size: 20px;
        }

        .block{
            display: flex;
            flex-direction: column;
            gap: 26px;
    
            .row{
                display: flex;
                align-items: center;
                gap: 7px;
                width: fit-content;
    
                .input_wrapper{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 15px;
    
                    span{
                        width: 200px;
                        display: block;
                    }
                
                    .input{
                        border: 1px #CCC solid;
                        padding: 8px 12px;
                        border-radius: 4px;
                        min-width: 300px;
                    }
                }
            }
        }

        .images_wrapper{
            position: relative;
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;

            .img_wrapper{
                width: calc(33% - 10px);
                box-shadow: 0px -6px 25.8px 0px rgba(0, 0, 0, 0.1);
                cursor: pointer;
                
                img{
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .footer{
            display: flex;
            gap: 15px;
            flex: 1 0 auto;
            align-items: flex-end;

            .success_button{
                padding: 8px 28px;
                background: #FF6600;
                color: #FFF;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 5px;
                height: 40px;

                svg{
                    width: 24px;
                    height: 24px;
                }

                span{
                    cursor: pointer;
                }
            }

            .reject_button{
                background: none;
                color: #FF6600;
                border: 1px #FF6600 solid;
                height: 40px;

                svg{
                    width: 16px;
                    height: 16px;
                }
            }

            
        }
    }
}