.modal_wrapper{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(3px);
    background: rgba(33,33,33,0.5);
    z-index: 999;

    .image_wrapper{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90vw;
        height: 90vh;
        object-fit: contain;

        .close_wrapper{
            position: absolute;
            bottom: calc(100% + 10px);
            left: calc(100% + 10px);
            cursor: pointer;

            svg{
                width: 25px;
                height: 25px;
                path{
                    fill: #000;
                }
            }
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}