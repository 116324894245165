.info_wrapper{
  display: flex;
  align-items: center;
  gap: 8px;
  .status_dot{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #00B998;
  }
  .status_text{
    font-weight: 700;
    color: #666666;
  }
  .status_date{
    color: #666666;
  }
}
.header{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
  .row{
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    flex-wrap: wrap;
    .add_button{
      white-space: nowrap;
      width: auto;
      border-radius: 2px;
      transition: .2s;
    }
    button{
      width: auto;
      display: flex;
      gap: 5px;
      svg{
        fill: #FF6600 !important;
        path{
          fill: #FF6600 !important;
        }
      }
    }
  }
  .search_wrapper{
    display: flex;
    align-items: center;
    gap: 10px;
    
    .pagination_wrapper{
      padding-left: 20px;
      div{
        margin: 0 !important;
      }
    }

  }
  .selected_menu{
    display: flex;
    align-items: center;
    gap: 16px;
    transition: .3s;
    overflow: hidden;
    height: 0px;
    box-sizing: border-box;
    button{
      padding: 4px 8px;
      height: fit-content;
      span{
        font-size: 14px;
        line-height: 19.6px;
      }
    }
  }
}

.content{
  margin-top: 20px;

  .table{
    width: 100%;
    thead{
      border-top: 2px #C2C2C2 solid;
      border-bottom: 2px #C2C2C2 solid;
    }
    tr{
      td{
        padding: 20px;
        .name{
          color: #FF6600;
        }
        .active{
          opacity: 1;
          visibility: visible;
        }
      }
      .t_user_name{
        position: relative;
        svg{
          width: 20px;
          height: 20px;
          position: absolute;
          top: 50%;
          left: 0px;
          transform: translate(-50%, -50%);
        }
      }
      .checkbox{
        width: 65px;
      }
      .td_menu{
        position: relative;
        width: 60px;
        height: 60px;
        span{
          width: 100%;
          height: 100%;
          display: block;
          cursor: pointer;
          img{
            pointer-events: none;
          }
        }
      }
      &:nth-child(2n) {
        background-color: #F5F3F766;
      }
    }
  }
}
.filters_wrapper{
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: hidden;
  height: 0;
  transition: .5s;
  padding: 0 2px;

  .titles{
    display: flex;
    gap: 12px;
    p{
      width: fit-content;
      color: #707075;
      span{
        color: #000;
      }
    }
    .clear_filters{
      color: #FF6600;
      cursor: pointer;
    }
  }
  .filters{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    .filter{
      display: flex;
      flex-direction: column;
      gap: 5px;
      flex: 1 0 auto;
      span{
        font-size: 12px;
      }
      input{
        height: 44px;
        border: 1px #E5E0EB solid;
        padding: 12px;
        width: 100%;
      }
      select{
        border-radius: 0px;
        height: 100%;
        width: 100%;
      }
    }
  }
  .buttons_wrapper{
    display: flex;
    gap: 10px;
    button{
      margin: 0;
      white-space: nowrap;
      width: -moz-fit-content;
      width: fit-content;
      border-radius: 4px;
    }
  }
}
