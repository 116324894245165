.wrapper{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(15px);
    display: flex;
    justify-content: center;
    align-items: center;
    .modal{
        padding: 20px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 0 10px 0 #00000040;
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 80%;
        height: 90%;
        .header{
            border-bottom: 1px #CCC solid;
            padding-bottom: 10px;
        }
        .groups{
            display: flex;
            flex-wrap: wrap;
            gap: 3px;
            .group_item{
                padding: 5px;
                border: 1px #CCC solid;
                border-radius: 4px;
                cursor: pointer;
                transition: .2s;
                user-select: none;
                p{
                    cursor: pointer;
                }
                &:hover{
                    background: #9f9cc7;
                    border: 1px transparent solid;
                    color: #FFF;
                }
            }
            .active{
                background: #FF6600;
                border: 1px transparent solid;
                color: #FFF;
            }
        }
        .content{
            display: flex;
            flex-direction: column;
            gap: 5px;
            overflow-y: auto;
            scrollbar-width: thin;
            height: 100%;
            .item{
                background-color: #f0f0f0;
                padding: 5px 10px;
                display: flex;
                align-items: center;
                position: relative;
                p{
                    position: relative;
                    overflow: visible;
                    svg{
                        width: 18px;
                        height: 18px;
                        margin-right: 10px;
                        cursor: pointer;
                    }
                }
                .delete{
                    svg{
                        position: absolute;
                        top: 50%;
                        right: 10px;
                        height: 17px;
                        transform: translateY(-50%);
                        cursor: pointer;
                    }
                }
            }
        }
        .footer{
            display: flex;
            gap: 10px;
            width: fit-content;
            margin: 0 auto;
            .button{
                white-space: nowrap;
                width: 100%;
                padding: 7px 10px;
                color: #FFF;
                background-color: #FF6600;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                cursor: pointer;
            }
        }
    }
    .info_wrapper{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 10px 0 #00000040;
        background-color: #fff;
        z-index: 20;
        transition: .2s;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .header{
            border-bottom: 1px #CCC solid;
            padding-bottom: 10px;
            text-align: center;
            color:#FF6600;
        }
        .response{
            max-width: 500px;
        }
    }
}