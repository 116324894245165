.header{
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 20px;

  .top_menu{
    display: flex;
    width: 100%;
    .menu_item{
      padding: 15px 24px;
      cursor: pointer;
      user-select: none;
      transition: .1s;
      border-bottom: 3px transparent solid;
      span{
        pointer-events: none;
      }
    }
    .active{
      border-bottom: 3px #FF6600 solid;
    }
  }
  .top_buttons{
    display: flex;
    width: 100%;
    justify-content: space-between;
    justify-content: flex-end;
    gap: 20px;
    button{
      margin-left: 0px;
    }
  }
  .statistic{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 26px 32px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    .statistic_block{
      display: flex;
      flex-direction: column;
      padding-right: 20px;

      .value{
        font-size: 32px;
        line-height: 44px;
        position: relative;
        width: fit-content;

        .procent{
          position: absolute;
          bottom: 0;
          left: 110%;
          font-size: 14px;
          color: #009B33;
        }
      }
      .desc{
        font-size: 14px;
        color: #666666;
      }
    }
  }
  .tools_wrapper{
    display: flex;
    flex-direction: column;
    .title_row{
      h2{
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

      }
    }
    .sort_row{
      width: 100%;
      display: flex;
      justify-content: space-between;
      .left_block{
        display: flex;
        flex-direction: column;
        gap: 10px;
        h3{
          font-size: 18px;
        }
        .row{
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: flex-start;
          padding: 6px 0;

          .filter_text{
            font-size: 16px;
            cursor: pointer;
          }
          img{
            cursor: pointer;
          }
        }
      }
      .right_block{
        display: flex;
        justify-content: flex-end;
        gap: 5px;
        .form_select{
          width: fit-content;
        }
      }
    }
  }
  .filters_wrapper{
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: hidden;
    height: 0;
    transition: .5s;
    padding: 0 2px;

    .titles{
      display: flex;
      gap: 12px;
      p{
        width: fit-content;
        color: #707075;
        span{
          color: #000;
        }
      }
      .clear_filters{
        color: #FF6600;
        cursor: pointer;
      }
    }
    .filters{
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      .filter{
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1 0 auto;
        span{
          font-size: 12px;
        }
        input{
          height: 44px;
          border: 1px #E5E0EB solid;
          padding: 12px;
          width: 100%;
        }
        select{
          border-radius: 0px;
          height: 100%;
          width: 100%;
        }
      }
    }
    .buttons_wrapper{
      display: flex;
      gap: 10px;
      button{
        margin: 0;
        white-space: nowrap;
        width: -moz-fit-content;
        width: fit-content;
        border-radius: 4px;
      }
    }
  }
}
.content{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.footer{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
