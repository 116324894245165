.modal_wrapper{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 6px 26px #00000026;
    background-color: #fff;
    padding: 15px;
    border-radius: 6px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 500px;
    min-width: 400px;
    
    .line{
        width: 100%;
        background-color: #e6e6e6;
        height: 10px;
        padding: 0;
    }

    .message_wrapper{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .header{
            color: #585858;
            display: flex;
            gap: 10px;
            flex-direction: column;
            // justify-content: space-between;
            // align-items: center;
            .recipient_wrapper{
                display: flex;
                gap: 7px;
                .recipient{
                    color: #FF6600;
                }
            }
        }
        .conditions_wrapper{
            display: flex;
            flex-direction: column;
            padding: 5px;
            gap: 3px;
            background: #F2F2F2;
            .condition_item{
                display: flex;
                gap: 5px;
                .condition_title{
                    color: #585858;
                    padding: 0;
                }
                .condition_variables{
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    .condition_variable{
                        padding: 0;
                        color: #585858;
                        .galka{
                            padding: 0;
                            color: green;
                        }
                    }
                }
            }
        }
        .messages_wrapper{
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 100%;
            .title{
                font-size: 12px;
                text-overflow: unset;
                white-space: normal;
                overflow: visible;
                border: 1px #CCC solid;
                width: 100%;
                padding: 5px;
                color: #585858;
            }
            .message{
                font-size: 12px;
                text-overflow: unset;
                white-space: normal;
                overflow: visible;
                border: 1px #CCC solid;
                width: 100%;
                padding: 5px;
                color: #585858;
            }
        }
    }
}