$font-fontDefault-Nunito: "Nunito", sans-serif;
$font-fontDefault-Popins: "Poppins", sans-serif;
$font-fontDefault-Dela: "Dela Gothic One", cursive;

$basicBG: rgba(248, 247, 253, 1);
$basic-color-text-title: rgba(16, 16, 16, 1);
$basic-color-borderInp: rgba(229, 224, 235, 1);
$basic-color-borderInpPlaceholder: rgba(171, 167, 175, 0.7);
$basic-color-borderInpRad: 4px;
$basic-color-violet: #FF6600;
$sidebar-dashboard: rgba(20, 25, 31, 1);
$sidebar-dashboard-text: rgba(26, 20, 31, 1);
$sidebar-dashboard-active: rgba(222, 219, 245, 1);
$sidebar-dashboard-activeIcon: rgba(75, 58, 90, 1);
$sidebar-dashboard-activeBorder: rgba(177, 170, 231, 1);
$header-verticalLine: rgba(202, 209, 225, 1);
$header-color-status: rgba(75, 58, 90, 1);
$table-border-color: rgba(194, 194, 194, 1);
$table-bg-line-color: rgba(245, 243, 247, 0.4);
$creatorInfoColorText: rgba(102, 102, 102, 1);
$moderation-payment-text: rgba(107, 119, 140, 1);
$userInfo-red-button: rgba(213, 26, 82, 1);

$gray-10: #f8fbff;
$gray-100: #ebf1fa;
$gray-200: #b6c3d3;
$gray-300: #bac8db;
$gray-400: #213a5c;
$gray-500: #93a6bf;
$gray-600: #e7edf5;
$gray-700: #565f6a;
$gray-800: #364a58;
$gray-50: #f2f7fc;
$blue: #1479ff;
$red: #ff4545;
$red-dark: #dc0000;
$green: rgba(0, 155, 51, 1);
$orange: #f6ab60;
$white: rgba(255, 255, 255, 1);

$primary: $blue;
$success: $green;
$warning: $orange;
$danger: $red;
$danger-dark: $red-dark;
$light: $gray-100;
$light-50: $gray-50;
$secondary: $gray-300;

$border-radius: 2px;

$box-shadow: 0 5px 1.875rem rgba(182, 195, 211, 0.6);
$box-shadow-lg: 0 1.25rem 3.75rem rgba(182, 195, 211, 0.5);

$border-radius-sm: 0.5rem;
$border-radius-lg: 0.75rem;

@mixin desctop {
  @media (max-width: 1199.98px) {
    @content;
  }
}

@mixin tabletMax {
  @media (max-width: 991.98px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 766.98px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 639.98px) {
    @content;
  }
}

@mixin calc($property, $maxSize, $minSize) {
  #{$property}: calc(
    #{$minSize+px} + (#{$maxSize} - #{$minSize}) * ((100vw - 320px) /
          (1440 - 320))
  );
}
@mixin centering() {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin starting() {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@mixin buttonCustom($width, $height, $text) {
  width: calc(#{$width + px});
  height: calc(#{$height + px});
  @include centering;
  background-color: $basic-color-violet;
  color: $white;
  border: 1px solid transparent;
  padding: 16px;
  font-size: calc(#{$text + px});
  font-weight: 400;
  border-radius: $basic-color-borderInpRad;
  margin-bottom: 10px;
  &:hover {
    background-color: $basic-color-text-title;
    border: 1px solid transparent;
    color: $white;
  }
}
@mixin pageTitle() {
  font-size: 48px;
  font-weight: 700;
  color: $basic-color-violet;

  @include tablet {
    font-size: 40px;
  }

  @include mobile {
    font-size: 32px;
  }
}
