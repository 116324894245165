.message_wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0;

    .header{
        display: flex;
        flex-direction: column;
        padding: 20px;
        gap: 15px;
        width: 100%;
        
        .header_item{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 15px;
            width: 100%;
            .label{
                font-weight: 500;
                white-space: nowrap;
            }
            .label_active{
                color: #FF6600;
                text-shadow: green 1px 0 10px;
            }
            svg{
                cursor: pointer;
            }
            .header_item_left, .header_item_right{
                display: flex;
                gap: 10px;
                align-items: center;
                flex: 1;
            }
            .header_item_right{
                display: flex;
            }
            .condition_wrapper{
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding-bottom: 30px;
                .content{
                    width: 100%;
                    display: flex;
                    gap: 20px;
                    .left{
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        background-color: rgb(250, 250, 250);
                        box-shadow: 0 6px 26px #00000026;
                        padding: 10px;
                        border-radius: 4px;
                        min-width: 40%;
                        max-width: 40%;
                        position: relative;

                        .list_item{
                            width: 100%;
                            padding: 15px 5px 15px 15px;
                            display: flex;
                            gap: 15px;
                            background-color: #F2F2F2;
                            .list_item_left{
                                display: flex;
                                width: 100%;
                                gap: 10px;
                                .label{
                                    font-weight: 600;
                                }
                
                                .list_item_values{
                                    display: flex;
                                    flex-wrap: wrap;
                                    gap: 10px;
                                    .variable{
                                        display: flex;
                                        align-items: center;
                                        gap: 3px;
                                        white-space: nowrap;
                                        span{
                                            transform: translateY(0px);
                                        }
                                    }
                                }
                            }
                            .list_item_right{
                                .remove_type_wrapper{
                                    width: 15px;
                                    height: 15px;
                                    svg{
                                        width: 15px;
                                        height: 15px;
                                    }
                                }
                            }
                        }

                        .remove_wrapper{
                            position: absolute;
                            right: 10px;
                            top: 10px;
                        }
                    }
                    .right{
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        gap: 45px;
                        .item{
                            display: flex;
                            flex-direction: column;
                            gap: 3px;
                            flex: 1;
                            textarea{
                                border-radius: 4px;
                                // box-shadow: 0 6px 26px #00000026;
                                padding: 5px;
                                border: 1px #CCC solid;
                                height: 100%;
                            }
                        }
                    }
                }
                .footer{
                    display: flex;
                    justify-content: center;
                    gap: 20px;
                }
            }
        }
        .messages_title{
            display: flex;
            align-items: center;
            border-top: 1px #CCC solid;
            padding: 20px 0px;
            h3{
                margin: 0;
            }
        }
        .header_types_list{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 30px;
        }
    }

    .conditionals_wrapper{
        flex: 1;
        .header{
            .title{
                font-weight: 600;
            }
        }
    }
    .variables_wrapper{
        flex: 1;
    }
    .buttons_wrapper{
        flex: 1;
    }

    input{
        border: 1px #CCC solid;
        border-radius: 4px;
        padding: 5px 7px;
        width: 100%;
        border-radius: 4px
    }

    select{
        padding: 5px 10px;
        border-radius: 2px;
        background: #FFF;
        border: 1px #CCC solid;
        border-radius: 4px;
    }

    .footer{
        display: flex;
    }
}