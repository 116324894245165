@import "../_variables";
//Modal ADD Terms
.add-edit-htmls {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  color: $sidebar-dashboard-text;
  position: relative;
  &_title {
    font-size: 20px;
    font-weight: 400;
    font-family: $font-fontDefault-Popins;
    color: $sidebar-dashboard-text;
  }
  &_top {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
  &_label {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    font-weight: 400;
    position: relative;
    margin-bottom: 28px;
    p {
      margin-bottom: 3px;
    }
  }
  &_inp,
  .add-edit-terms_center-inp {
    width: inherit;
    height: 36px;
    border: 1px solid $basic-color-borderInp;
    border-radius: $basic-color-borderInpRad;
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
  }
  &_inp {
    padding: 8px 12px;
    height: 110px;
  }
  &_length {
    position: absolute;
    bottom: -15px;
    right: 0;
  }
  &_center {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin: 16px 0;
    font-size: 12px;
    font-weight: 400;
    &-inp {
      width: 100%;
      padding: 8px 12px;
      margin-top: 4px;
      &::placeholder {
        padding: 0;
      }
    }
  }
  &_checkboxEdit {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
  }
  &_checkboxInp {
    margin-right: 10px;
  }
  &_close {
    background-color: transparent;
    border: 1px solid $basic-color-violet;
    color: $basic-color-violet;
    &:hover {
      color: $white;
      background-color: $basic-color-violet;
    }
  }
  &_save {
    background-color: $basic-color-violet;
    border: 1px solid $basic-color-violet;
    &:hover {
      color: $white;
      background-color: $sidebar-dashboard;
    }
  }
  &_block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-info {
      @include centering;
      p {
        margin-right: 12px;
        font-size: 14px;
        font-weight: 400;
      }
    }
    &-dropdown {
      width: 100%;
      height: 36px;
      border: 1px solid $basic-color-borderInp;
      border-radius: $basic-color-borderInpRad;
      background-color: transparent;
      text-align: left;
      color: $basic-color-borderInpPlaceholder;
      font-size: 14px;
      font-weight: 400;
      padding: 0 12px;
      margin-top: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover {
        border-color: $basic-color-violet;
      }
    }
  }
}
