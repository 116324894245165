@import "../_variables";
.terms {
  padding: 30px;
  animation: animationUnitForPriceModalTerms ease 0.8s forwards;
  margin-left: 220px;

  @include tablet {
    padding: 16px;
  }

  // @include tabletMax {
  //   margin-left: 0;
  // }

  &-title {
    @include pageTitle;
  }
  &-wrapper {
    position: relative;
    width: 100%;
    background-color: $white;
    padding: 20px;
    //min-height: 90vh
    min-height: max-content;

    &_addButton {
      @include buttonCustom(140, 40, 16);
      margin: 0 3px 0 0;

      img {
        margin-right: 8px;
      }
    }
    &_search {
      margin: 20px 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-block {
        width: 280px;
        position: relative;
      }
      &-inp {
        width: 280px;
        height: 36px;
        border: 1px solid $basic-color-borderInp;
        border-radius: $border-radius;
        padding: 8px 40px 8px 12px;

        &::placeholder {
          padding: 0;
        }
        &:active,
        &:focus,
        &:focus-visible {
          outline: 1px solid $basic-color-violet !important;
          border: none;
        }
      }
      &-button {
        width: 35px;
        height: 36px;
        background-color: $basic-color-violet;
        position: absolute;
        right: 0;
        top: 0;
        border-radius: $basic-color-borderInpRad;

        &:hover {
          background-color: $basic-color-text-title;
          border: 1px solid transparent;
          color: $white;
        }
        &_clear {
          position: absolute;
          background-color: transparent;
          right: 40px;
          top: 10px;
        }
      }
      &-showList {
        @include centering;

        &_select {
          width: 68px;
          border: none;
          color: $basic-color-violet;
          text-align: center;
        }
        &_title {
          color: $sidebar-dashboard-text;
          font-size: 16px;
          font-weight: 400;
        }
        .dropdown {
          border: none;

          .dropdown-toggle {
            color: $basic-color-violet;
            background-color: transparent !important;
            border: none;
          }
          .dropdown-menu {
            width: max-content;
            --bs-dropdown-min-width: 0;

            .dropdown-item:active {
              background-color: $basic-color-violet;
            }
          }
        }
      }
      &-types {
        border-bottom: 1px solid $basic-color-borderInpPlaceholder;
        margin-top: 25px;
        display: flex;

        &_button {
          padding: 6px 16px;
          background-color: transparent;
          border-bottom: 2px solid transparent;
          display: flex;
          gap: 5px;
          align-items: center;

          &_dot {
            width: 20px;
            height: 20px;
            font-size: 11px;
            background: #FF6600;;
            border-radius: 50%;
            color: #FFF;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &:hover {
            border-bottom: 2px solid rgba(255, 102, 0, 0.7);
          }
        }
      }
    }
    &_itemsInfo {
      margin: 8px 0;

      &-content {
        font-family: $font-fontDefault-Popins;
        font-size: 16px;
        font-weight: 400;
        color: $sidebar-dashboard-text;
      }
    }
    &_tabl {
      &-head {
        border-top: 1px solid $table-border-color;
        border-bottom: 1px solid $table-border-color;
      }
      &-trow {
        &_item {
          position: relative;
          font-size: 16px;
          font-weight: 400;
          font-family: $font-fontDefault-Popins;
          padding: 12px !important;
          white-space: nowrap;

          &:first-child {
            cursor: pointer;

            span {
              width: 90%;
              margin-left: 8px;
              cursor: pointer;
              white-space: nowrap;
            }
            img {
              position: absolute;
              right: 12px;
            }
          }
          img {
            margin-left: 9px;
          }
          &:last-child {
            img {
              position: absolute;
              right: 16px;
              top: 12px;
            }
          }
          &:nth-child(2) {
            span {
              cursor: pointer;
            }
          }
          &:nth-child(3) {
            cursor: pointer;

            span {
              cursor: pointer;
            }
          }
          &:nth-child(4) {
            cursor: pointer;

            span {
              cursor: pointer;
            }
          }
        }
      }
      &-body {
        position: relative;

        &_item {
          //padding: 20px 12px !important
          //max-width: 600px
          position: relative;
          overflow-y: hidden;
          max-width: 150px;
          vertical-align:middle;

          p {
            font-weight: 300;
            font-family: $font-fontDefault-Popins;
            line-height: 148%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 95%;
          }
          span {
            white-space: nowrap;
          }
          &-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 3px;
            // position: relative;
            overflow-y: hidden;
            max-width: 150px;
            scrollbar-width: thin;

            &_icons {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 60px;
              max-width: 100px;
              //position: absolute
              //right: 20px
              button {
                background-color: transparent;
                margin-left: 10px;
              }
            }
            &_input {
              margin-bottom: 2px;
              margin-right: 4px;
            }
            &_values {
              max-height: 100px;
              overflow-x: auto;

              li {
                margin: 5px;
              }
              //Modal ADD Terms
            }
          }
        }
      }
    }
  }
}
.add-edit-terms {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  color: $sidebar-dashboard-text;

  &_title {
    font-size: 20px;
    font-weight: 400;
    font-family: $font-fontDefault-Popins;
    color: $sidebar-dashboard-text;
  }
  &_top {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }
  &_label {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    font-weight: 400;
    margin-top: 12px;
    .is-invalid ~ .invalid-feedback {
      text-align: center;
    }
  }
  &_inp,
  .add-edit-terms_center-inp {
    height: 36px;
    border: 1px solid $basic-color-borderInp;
    border-radius: $basic-color-borderInpRad;
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
  }
  &_inp {
    width: 100%;
    padding: 8px 12px;
  }
  &_center {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin: 16px 0;
    font-size: 12px;
    font-weight: 400;

    &-inp {
      width: 100%;
      padding: 8px 12px;

      &::placeholder {
        padding: 0;
      }
    }
  }
  &_checkboxEdit {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
  }
  &_checkboxInp {
    margin-right: 10px;
  }
  &_close {
    background-color: transparent;
    border: 1px solid $basic-color-violet;
    color: $basic-color-violet;

    &:hover {
      color: $white;
      background-color: $basic-color-violet;
    }
  }
  &_save {
    background-color: $basic-color-violet;
    border: 1px solid $basic-color-violet;

    &:hover {
      color: $white;
      background-color: $sidebar-dashboard;
    }
  }
  &_block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-info {
      @include centering;

      p {
        margin-right: 12px;
        font-size: 14px;
        font-weight: 400;
      }
    }
    &-dropdown {
      width: 100%;
      height: 36px;
      border: 1px solid $basic-color-borderInp;
      border-radius: $basic-color-borderInpRad;
      background-color: transparent;
      text-align: left;
      color: $basic-color-borderInpPlaceholder;
      font-size: 14px;
      font-weight: 400;
      padding: 0 12px;
      //margin-top: 4px
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      overflow-y: auto;
      margin: 4px 0 15px 0;

      &:hover {
        border-color: $basic-color-violet;
      }
      span {
        color: $sidebar-dashboard-text;
      }
    }
  }
}
.modal.show .modal-dialog {
  max-width: 605px;
}
.modal-footer {
  display: flex;
  flex-direction: row-reverse;
}
.page-link {
  border: none;
  color: $sidebar-dashboard-text;
}
.active > .page-link {
  background-color: transparent;
  color: $basic-color-violet;
  font-size: 18px;
  font-weight: 900;
  transform: translateY(-3px);
}
.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 15px 0;
}
.pagination-input-block {
  max-width: 100px;
  margin-left: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &_inp {
    width: 50px;
    color: $basic-color-violet;
    font-size: 18px;
    font-weight: 900;
  }
  span {
    white-space: nowrap;
    margin-left: 8px;
  }
}
.term-types_active {
  border-bottom: 2px solid $basic-color-violet;
}
.template-add_button {
  width: 225px;
  white-space: nowrap;
}
@keyframes animationUnitForPriceModalTerms {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
