.content{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  h3{
    margin-bottom: 5px;
  }
  .input_wrapper{
    display: flex;
    flex-direction: column;
    gap: 5px;
    label{
      font-size: 12px;
    }
    input{
      width: 700px;
      height: 44px;
      border: 1px #E5E0EB solid;
      padding: 12px;
    }
  }

}