.body_row{
    width: 100%;
    display: flex;
    height: 350px;
    gap: 50px;
    .left{
        width: 50%;
        margin-bottom: 0;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        textarea{
            width: 100%;
            margin-top: 0;
            height: 100%;
        }
    }
    .right{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .image_wrapper{
            height: 100%;
        }
    }
}