.wrapper{
    display: flex;
    flex-direction: column;
    gap: 15px;

    .header{
        display: flex;
        justify-content: space-between;
        gap: 15px;
        width: 100%;

        .header_row{
            display: flex;
            align-items: center;
            gap: 20px;
        }
    }

    .table{
        width: 100%;
        border-collapse: collapse;
    
        thead{
            border-top: 1px solid #c2c2c2;
            border-bottom: 1px solid #c2c2c2;

            tr{
                font-weight: 600;
                .cursor{
                    cursor: pointer;
                    user-select: none;

                    &::after{
                        content: url("../../../utils/icons/arrow-sorting.svg");
                        position: relative;
                        left: 8px;
                        top: 3px;
                    }
                }
            }
        }

        tbody{
            tr{
                border-top: 1px solid #c2c2c2;
                border-bottom: 1px solid #c2c2c2;
                opacity: 0.9;
                cursor: pointer;

                .status{
                    width: 100%;
                    padding: 4px 8px;
                    background: #6B778C;
                    border-radius: 2px;
                    font-size: 13px;
                    color: #FFF;
                    display: block;
                    text-align: center;
                    white-space: nowrap;
                }

                .moderation{
                    background: #6B778C;
                    color: #FEFEFE;
                }
    
                .active{
                    background: #00A046;
                    color: #FFF;
                }
    
                .rejected{
                    background: #FF4838;
                    color: #FFF;
                }

                .archieve{
                    background: #694040;
                    color: #FFF;
                }

                .deleted{
                    background: #000000;
                    color: #FFF;
                }

                .banned{
                    background: #9c0b0b;
                    color: #FFF;
                }

                .menu{
                    .user_icon{
                        cursor: pointer;
                    }

                    .eye_icon{
                        cursor: pointer;
                    }
                }

                &:nth-child(odd){
                    background: rgba(222, 219, 245, 0.5);
                }

                &:hover{
                    opacity: 1;
                }
            }
        }
        td{
            width: 12%;
            padding: 10px 5px;
    
            .button_wrapper{
                    width: 22px;
                    height: 22px;
                    cursor: pointer;
                svg{
                    width: 22px;
                    height: 22px;
                }
            }
        }

        .id{
            width: 3%;
        }
    
        .menu_td{
            width: 3%;
        }
    }
}
.no_data{
    text-align: center;
}