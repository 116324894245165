@import "../_variables";
.template {
  &-content {
    padding: 30px;
    background-color: $white;

    &_category {
      width: 100%;
      padding: 30px;
      border: 1px solid $basic-color-borderInp;
      margin-top: 20px;

      &-title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 16px;
      }
      &-body {
        &_breadcrumbs {
          &-label {
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 4px;
          }
          &-text {
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            padding: 8px 12px;
            border: 1px solid $basic-color-borderInp;
            max-width: 480px;
            height: max-content;
            min-height: 30px;
            text-align: left;
            background-color: transparent;
            position: relative;
            color: $basic-color-borderInpPlaceholder;
            &-img {
              position: absolute;
              right: 10px;
              top: 12px;
            }
          }
        }
        &_list {
          max-width: 480px;
          width: 100%;
          max-height: 825px;
          height: max-content;
          overflow-x: auto;
          scrollbar-width: thin;
          padding: 16px;
          margin-top: 16px;
          box-shadow: 0 0 16px rgba(62, 19, 77, 0.07);
          border-radius: $border-radius;

          &-dropdown {
            width: 100%;
            padding: 10px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            position: relative;
            flex-direction: column;

            &_toggle,
            &_menu {
              width: inherit;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              position: relative;
              background: transparent;
              font-size: 14px;
              font-weight: 400;
              color: $sidebar-dashboard-text;
              margin-bottom: 10px;

              &-counter {
                position: absolute;
                @include centering;
                right: 0;

                p {
                  width: 20px;
                  height: 20px;
                  @include centering;
                  border: 1px solid $basic-color-violet;
                  background-color: $basic-color-violet;
                  color: $white;
                  margin-right: 20px;
                  border-radius: 50%;
                  padding-top: 1px;
                  font-weight: 700;
                  font-size: 13px;
                }
              }
            }
            &_menu {
              align-items: flex-start;
              flex-direction: column;
              padding: 0 0 0 20px;
              background-color: $white;

              &-item {
                width: inherit;
                margin: 6px 0;
                cursor: pointer;
                position: relative;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                padding: 3px;
                background-color: transparent;

                &_inp {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  cursor: pointer;

                  input {
                    cursor: pointer !important;
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
    &_valueBlock {
      &-list {
        width: 100%;
        margin-top: 20px;
        max-height: 300px;
        min-height: 60px;
        overflow-x: auto;
        padding-right: 5px;

        &_li {
          background-color: transparent;
          position: relative;
          input {
            margin-right: 5px;
          }
          &-text {
            border: 1px solid $basic-color-borderInp;
            border-radius: $border-radius;
            padding-right: 0;
            cursor: grab;
          }
          &-buttons {
            width: auto;
            display: flex;
            align-items: center;
            &_btn {
              width: auto;
              height: 100%;
              margin: 0 10px;
              padding: 10px 16px;
              @include centering;
              background-color: $basic-color-violet;
              color: $white;
              border: 1px solid transparent;
              border-radius: $basic-color-borderInpRad;
              &:hover {
                background-color: $basic-color-text-title;
                border: 1px solid transparent;
                color: $white;
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
          &-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }
    &_rules {
      width: 100%;
      padding: 30px;
      border: 1px solid $basic-color-borderInp;
      margin-top: 20px;
      &-body {
        max-width: 480px;
        width: 100%;
        &-label {
          margin-bottom: 4px;
          font-size: 12px;
        }
      }
      &-select {
        margin-bottom: 20px;
        border-radius: $border-radius !important;
        &-editName {
          margin-bottom: 20px;
          border-radius: $border-radius !important;
          font-size: 14px;
          padding: 8px 12px;
          border: 1px solid $basic-color-borderInp;
          background-color: $basic-color-borderInp;
        }
      }
      &-checkbox,
      .form-check {
        margin-bottom: 15px !important;
        display: flex;
        align-items: center;
        label {
          margin-left: 10px;
          font-size: 14px;
        }
      }
    }
  }
}
.form-check-input {
  margin-top: 0 !important;
}
.showDropdown {
  .template-content_category-body_list-dropdown_toggle-counter {
    img {
      transform: rotate(-90deg);
    }
  }
}
