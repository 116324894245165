.wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 500px;

    header{
        display: flex;
        gap: 10px;
        align-items: center;
        position: relative;

        h3{
            margin: 0;
        }

        .langs_wrapper{
            display: flex;
            gap: 8px;

            .lang_item{
                border: 1px #CCC solid;
                border-radius: 4px;
                padding: 5px 8px;
                cursor: pointer;
                display: flex;
                gap: 7px;
                transition: .3s;
                text-transform: uppercase;
                user-select: none;

                span{
                    user-select: none;
                    cursor: pointer;
                }
            }

            .active_lang_item{
                border: 1px #FF6600 solid;
                box-shadow: 0px 0px 8px 1px #847ce2;
            }

            .completed_lang_item{
                border: 1px #FF6600 solid;
                background: #FF6600;
                color: #FFF;
            }
        }
        .translator_wrapper{
            position: relative;
            width: 60px;
            height: 30px;
            transform: translateY(20px);
            position: absolute;
            top: 0;
            right: -15px;
        }
    }

    .input_wrapper{
        display: flex;
        gap: 5px;
        align-items: center;

        span{
            text-transform: uppercase;
        }
    }
}