.category_item{
    border: 1px #ececec solid;
    padding: 5px;
    margin-bottom: 5px;
    transition: .3s;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    border-radius: 5px;
    .header{
        background-color: #4e97eb;
        padding: 5px 10px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        transition: .3s;
        &:hover{
            background-color: #3579c7;
        }
        .title{
            color: #FFF;
            user-select: none;
            flex-grow: 1;
            text-align: center;
        }
        .number{
            color: #FFF;
            font-size: 12px;
            transform: translateY(1px);
        }
    }
    
    .category_item{
        width: 95%;

    }

    .subcategories_wrapper{
        width: 95%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        border-radius: 5px;
        overflow: hidden;
        height: 0px;
        transition: .5s;

    }

    .subcategory_wrapper{
        width: 95%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        border-radius: 5px;
        transition: .5s;

        .subcategory_header{
            padding: 5px;
            border: 1px #ececec solid;
            width: 100%;
            border-radius: 5px;
            user-select: none;
            display: flex;
            gap: 5px;
            width: 100%;
            transition: .3s;

            &:hover{
                background-color: #d3d2d2;
            }
        }
    }
}
