.content{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 150px !important;
    .top_button_wrapper{
        display: flex;
        span{
            font-size: 18px;
            padding: 15px 24px;
            cursor: pointer;
            transition: .2s;
            user-select: none;
        }
    }
   
    .content_wrapper{
        table{
            width: 100%;
            background: #FFF;
            thead{
                border-top: 2px #CCC solid;
                border-bottom: 2px #CCC solid;
                tr{
                    td{
                        padding: 20px 20px;
                    }
                }
            }
            tbody{
                tr{
                    &:nth-child(2n){
                        background: #FBFAFC;
                    }
                    td{
                        padding: 20px 20px;
                        .shape{
                            display: block;
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                            background-color: red;
                        }
                    }
                    .tr_names{
                        position: relative;
                        cursor: pointer;
                        display: flex;
                        &:hover{
                            .names_list{
                                opacity: 1;
                                visibility: visible;
                                z-index: 1;
                            }
                        }
                        .names_arrow{
                            transform: rotate(-90deg);
                            width: fit-content;
                            height: fit-content;
                            margin-left: 7px;
                            &::after{
                                content: "\276E";
                                
                            }
                        }

                        .names_list{
                            max-height: 200px;
                            overflow-y: auto;
                            scrollbar-width: thin;
                            position: absolute;
                            left: 0px;
                            top: 100%;
                            background: #FFF;
                            padding: 15px;
                            border-radius: 3px;
                            box-shadow: 0px 0px 10px -4px #000;
                            display: flex;
                            flex-direction: column;
                            gap: 5px;
                            visibility: hidden;
                            opacity: 0;
                            transition: .2s;
                        }
                        .show_list{
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                    .td_menu{
                        width: fit-content;
                        position: relative;
                        .dots_wrapper{
                            padding: 10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: fit-content;
                            cursor: pointer;
                        }
                        .tools_modal{
                            position: absolute;
                            top: 35px;
                            right: 0;
                            display: flex;
                            flex-direction: column;
                            background-color: #fff;
                            padding: 8px 4px;
                            z-index: 5;
                            box-shadow: 0px 0px 16px 0px rgba(54, 54, 54, 0.16);
                            border-radius: 8px;
                            gap: 5px;
                            opacity: 0;
                            visibility: hidden;
                            .item{
                              transition: .3s;
                              width: 100%;
                              span{
                                padding: 8px 4px;
                                font-size: 16px;
                                width: 100%;
                                display: block;
                                cursor: pointer;
                                user-select: none;
                                &:hover{
                                  background-color: #EDEBE9;
                                }
                              }
                        
                            }
                            .delete{
                              color: #D51A52;
                            }
                            .modal_wrapper{
                              position: fixed;
                              left: 0;
                              top: 0;
                              width: 100vw;
                              height: 100vh;
                              z-index: -1;
                            }
                          }
                          .active{
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}