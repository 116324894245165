.page{
    display: flex;
    flex-direction: column;
    gap: 15px;

    .header{
        box-shadow: 0px 2px 8px 0px #00000029;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 8px 10px;
    
        .user_wrapper{
            display: flex;
            gap: 15px;
    
            .logo{
               width: 67px;
               height: 67px; 
               
               img{
                    width: 67px;
                    height: 67px; 
               }
            }
    
            .user_info{
                display: flex;
                flex-direction: column;
                gap: 5px;
    
                .status{
                    width: fit-content;
                    padding: 4px 8px;
                    background: #000;
                    border-radius: 2px;
                    font-size: 13px;
                }
                
                .moderation{
                    background: #6B778C;
                    color: #FEFEFE;
                }

                .active{
                    background: #00A046;
                    color: #FFF;
                }

                .rejected{
                    background: #FF791F;
                    color: #FFF;
                }

                .banned{
                    background: #FF4838;
                    color: #FFF;
                }
    
                .name{
                    font-size: 20px;
                }
    
                .email{
                    font-size: 12px;
                }
            }
        }
    
        .line{
            width: 100%;
            height: 3px;
            background: #FF6600;
            border-radius: 3px;
        }
    }
}