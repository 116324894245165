.feedback_wrapper {
  max-width: 280px;
  border: 2px #dedbf5 solid;
  border-radius: 8px;
  padding: 10px;
  max-height: 250px;
  height: 250px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .feedback_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .left {
      display: flex;
      align-items: center;
      gap: 5px;
      img {
        width: 20px;
        height: 20px;
      }
      .title {
        color: #FF6600;
        font-weight: 700;
        margin-right: 5px;
      }
    }
  }
  .feedback_content {
    flex: 1 1 auto;
    font-size: 14px;
    overflow: auto;
    scrollbar-width: thin;
  }
  .feedback_footer {
    width: 100%;
    color: #7e7e7e;
    text-align: right;
    font-size: 12px;
  }
}
