.content{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  .row{
    display: flex;
    gap: 15px;
    position: relative;
    width: fit-content;

    h2{
      margin-bottom: 5px;
      font-size: 18px;
    }
    svg{
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
    svg:hover + img{
      opacity: 1;
      visibility: visible;
    }
    img{
      position: absolute;
      top: 50%;
      left: 100%;
      opacity: 0;
      visibility: hidden;
      transition: .3s;
    }
  }
  .add_button{
    opacity: 0.5;
  }
}