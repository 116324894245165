.buttons_wrapper{
    width: 100%;
    box-shadow: 0px 0px 20px -5px #CCC;
    border-radius: 8px;
    margin-top: 10px;
    padding: 10px;
    display: flex;
    gap: 15px;

        .success_button{
            padding: 8px 28px;
            background: #FF6600;
            color: #FFF;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 5px;

            svg{
                width: 24px;
                height: 24px;
            }

            span{
                cursor: pointer;
            }
        }

        .reject_button{
            background: none;
            color: #FF6600;
            border: 1px #FF6600 solid;

            svg{
                width: 16px;
                height: 16px;
            }
        }

        .block_button{
            background: none;
            color: #c00000;
            border: 1px #c00000 solid;

            svg{
                width: 16px;
                height: 16px;
                path{
                    fill: #c00000;
                }
            }
        }
}