.preview_wrapper{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(15px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 100;
    gap: 20px;
    .modal{
        width: 90%;
        height: 90%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .mobile_wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            img{
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .email_wrapper{
                position: relative;
                z-index: 100;
                width: 23.5%;
                transform: translate(0%, 19%);
                height: 61%;
                iframe{
                    width: 100%;
                    height: 100%;
                }
            }
            .title_wrapper{
                position: absolute;
                top: 18.5%;
                left: 38.8%;
                max-width: 22%;
                font-size: 18px;
            }
        }
        .desktop_wrapper{
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
            }
            .email_wrapper{
                position: relative;
                z-index: 100;
                width: 59%;
                transform: translate(5%, -1%);
                height: 54%;
                iframe{
                    width: 100%;
                    height: 100%;
                }
            }
            .title_wrapper{
                position: absolute;
                top: 16.5%;
                left: 26.8%;
                color: #505050;
            }
        }
    }
    .lang_bar{
        position: absolute;
        display: flex;
        gap: 10px;
        top: 10px;
        left: 10px;
        z-index: 100;
        button{
            padding: 5px;
            width: fit-content;
            height: fit-content;
        }
    }
    .footer{
        display: flex;
        gap: 15px;
    }
}