.content{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 150px !important;
  margin-top: 15px;
  .tabs_wrapper{
    span{
      text-transform: none;
    }
  }
  .top_button_wrapper{
    display: flex;
    span{
      font-size: 18px;
      padding: 15px 24px;
      cursor: pointer;
      transition: .2s;
      user-select: none;
    }
  }

  .content_wrapper{
    table{
      width: 100%;
      background: #FFF;
      thead{
        border-top: 2px #CCC solid;
        border-bottom: 2px #CCC solid;
        tr{
          td{
            padding: 20px 20px;
          }
        }
      }
      tbody{
        tr{
          &:nth-child(2n){
            background: #FBFAFC;
          }
          td{
            padding: 20px 20px;
            .statistics_button_active{
              svg{
                path{
                  fill: #FF6600 !important;
                }
              }
            }
          }
          
        }
      }
    }
  }
}