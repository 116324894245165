@import "../_variables";
.moderation {
  &-payment {
    &_block {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: row;
      margin-top: 30px;
      &-content {
        max-width: 620px;
        width: 100%;
        background-color: $white;
        padding: 20px;
        border-radius: 8px;
        &_wrapper {
          &-title {
            font-weight: 400;
            font-size: 20px;
            border-bottom: 3px solid $basic-color-violet;
            padding-bottom: 5px;
          }
          &-ul {
            &_li {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 20px 0;
              font-size: 16px;
              font-weight: 400;
              &-title {
                color: $moderation-payment-text;
              }
              &-value {
                color: $sidebar-dashboard-text;
                img {
                  margin-left: 10px;
                }
              }
              &-status {
                padding: 4px 8px;
                border-radius: 2px;
                background-color: rgba(247, 16, 16, 1);
                color: $white;
              }
            }
          }
          &-alert {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            min-height: 105px;
            border-radius: 4px;
            padding: 0 20px;
            border-left: 6px solid rgba(0, 131, 195, 0.3);
            border-top: 1px solid $basic-color-borderInp;
            border-right: 1px solid $basic-color-borderInp;
            border-bottom: 1px solid $basic-color-borderInp;
            &_img {
              width: 40px;
              min-width: 40px;
              height: 40px;
              min-height: 40px;
              background-color: rgba(0, 131, 195, 0.3);
              border-radius: 50%;
              @include centering;
            }
            &_content {
              margin-left: 20px;
              &-title {
                color: rgba(0, 131, 195, 1);
                font-size: 16px;
                font-weight: 700;
              }
              &-text {
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }
      }
      &-buttons {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        margin-left: 20px;
        &_but {
          @include buttonCustom(327, 40, 16);
          b {
            margin: 0 5px;
            font-weight: 900;
          }
          &:nth-child(2),
          &:nth-child(3) {
            @include buttonCustom(327, 40, 16);
            background-color: $white;
            color: $basic-color-violet;
            border: 1px solid $basic-color-violet;
          }
        }
      }
    }
  }
}
