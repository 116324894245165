.modal_wrapper{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(15px);
    .modal{
        max-height: 80vh;
        overflow-y: auto;
        background-color: #fff;
        padding: 30px;
        opacity: 0;
        display: flex;
        flex-direction: column;
        gap: 15px;
        animation: show_modal .5s forwards;
        box-shadow: 0px 0px 10px 2px #ccc;
        border-radius: 8px;
        scrollbar-width: thin;

        .title{
            font-weight: 600;
            text-align: center;
            font-style: 20px;
        }

        .row{
            position: relative;
            input{
                height: 44px;
                border: 1px #E5E0EB solid;
                padding: 12px;
                width: 100%;
                margin-top: 5px;
            }
            .error{
                box-shadow: 0px 0px 10px -4px red;
            }
            svg{
                position: absolute;
                top: 50%;
                left: -20px;
                cursor: pointer;
            }
        }
        .add_name_button{
            font-size: 14px;
            color: #FFF;
            border-radius: 3px;
            background-color: #FF6600;
            width: fit-content;
            padding: 5px 3px;
            cursor: pointer;
            user-select: none;
        }
        .row_buttons{
            display: flex;
            gap: 10px;
            .loading{
                button{
                    opacity: .5;
                    user-select: none;
                }
            }
        }
    }

    .filter_wrapper{
        width: 100vw;
        height: 100vh;
        animation: show_wrapper .3s forwards;
        backdrop-filter: blur(10px);
    }
}

.hide_wrapper{
    animation: hide_wrapper .5s forwards !important;
    animation-delay: .5s;
}
.hide_modal{
    animation: hide_modal .5s forwards !important;
}

@keyframes show_modal {
    0%{
       opacity: 0;
       transform: translateY(-10%); 
    }
    100%{
        opacity: 1;
        transform: translateY(0%);
     }
}
@keyframes show_wrapper {
    0%{
       opacity: 0;
    }
    100%{
        opacity: 1;
     }
}
@keyframes hide_modal {
    0%{
       opacity: 1;
       transform: translateY(0%); 
    }
    100%{
        opacity: 0;
        transform: translateY(-10%); 
     }
}
@keyframes hide_wrapper {
    0%{
       opacity: 1;
    }
    100%{
        opacity: 0;
     }
}