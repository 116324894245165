.notification{
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 100%;
  padding: 12px 21px;
  display: flex;
  gap: 24px;
  .left{
    .img_wrapper{
      width: 40px;
      height: 40px;
      position: relative;
      img{
        width: 100%;
        height: 100%;
      }
      svg{
        position: absolute;
        top:0;
        right: 0;
        width: 13px;
        height: 13px;
        fill: #65D15C;
      }
    }
  }
  .right{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .send_date{
        color: #999999;
        font-size: 12px;
      }
      .validation{
        font-size: 12px;
        margin-left: 20px;
        color: #ff6d6d;
        svg{
          transform: translateY(-2px);
        }
      }
      .validation_link{
        color:#FF6600;
        cursor: pointer;
      }
      .img_wrapper{
        cursor: pointer;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img{
          pointer-events: none;
          transform: rotate(90deg);
        }
        .tools_modal{
          position: absolute;
          top: 35px;
          right: 0;
          display: flex;
          flex-direction: column;
          background-color: #fff;
          padding: 8px 4px;
          z-index: 5;
          box-shadow: 0px 0px 16px 0px rgba(54, 54, 54, 0.16);
          border-radius: 8px;
          gap: 5px;
          opacity: 0;
          visibility: hidden;
          .item{
            transition: .3s;
            width: 100%;
            span{
              padding: 8px 4px;
              font-size: 16px;
              width: 100%;
              display: block;
              cursor: pointer;
              user-select: none;
              &:hover{
                background-color: #EDEBE9;
              }
            }
      
          }
          .delete{
            color: #D51A52;
          }
          .modal_wrapper{
            position: fixed;
            left: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            z-index: -1;
          }
        }
        .active{
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .center{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 20px;

      .left{
        max-width: 50%;
      }
      .right{
        max-width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .item{
        display: flex;
        flex-direction: column;
        .value{
          font-size: 32px;
          color: #FF6600;
          line-height: 42px;
          position: relative;
          width: fit-content;

          .procent{
            position: absolute;
            bottom: 0;
            left: 110%;
            font-size: 14px;
            color: #009B33;
          }
        }
        .value_name{
          transform: translateX(-10px);
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          cursor: pointer;
        }
        .desc{
          font-size: 14px;
          color: #666666;
        }
      }
    }
    .bottom{
      display: flex;
      align-items: center;
      gap: 16px;
      .progress_bar{
        flex: 1 0 auto;
      }
      .result{
        svg{
          fill: #00B998;
          width: 24px;
          height: 24px;
        }
        span{
          color: #FF69B4;
          font-size: 18px;
        }
      }
    }
  }
}