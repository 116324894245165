@import "../_variables";

.template-content {
  padding: 30px;
  background-color: $white;
  &_nameBlock,
  &_typeBlock,
  &_defaultValueBlock,
  &_valueBlock {
    width: 100%;
    padding: 30px;
    border: 1px solid $basic-color-borderInpPlaceholder;
    &-title {
      font-size: 16px;
      font-weight: 500;
    }
    &-wrapper {
      width: 100%;
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 16px;
      gap: 10px;
      flex-wrap: wrap;
    }
    &-label {
      width: inherit;
      max-height: 50px;
      font-size: 12px;
      font-weight: 400;
      max-width: calc(25% - 10px);
      color: $sidebar-dashboard-text;
      // &:nth-child(2) {
      //   margin: 0 15px 0 15px;
      // }
      // &:nth-child(3) {
      //   margin: 0 15px 0 0px;
      // }
      p,
      span {
        margin-bottom: 4px;
      }
      &_psevdo {
        width: 32%;
        max-height: 50px;
        font-size: 12px;
        font-weight: 400;
        color: $sidebar-dashboard-text;
      }
    }
    &-inp {
      border-radius: $border-radius;
      &::placeholder {
        padding: 0;
        color: $basic-color-borderInpPlaceholder;
      }
      &_psevdo {
        border-radius: $border-radius;
        &::placeholder {
          padding: 0;
          color: $basic-color-borderInpPlaceholder;
        }
      }
    }
  }
  &_typeBlock,
  &_defaultValueBlock,
  &_valueBlock {
    margin-top: 20px;
    &-title {
      margin-bottom: 16px;
    }
    &-label {
      width: 32%;
      margin: 0 !important;
      &_key {
        margin-top: 20px;
        width: 32%;
        .template-content_typeBlock-select {
          margin-top: 4px;
        }
      }
    }
    &-checkbox {
      display: flex !important;
      align-items: center;
      justify-content: flex-start;
      margin-top: 16px;
      span {
        font-size: 16px;
        font-weight: 400;
        color: $sidebar-dashboard-text;
        margin-left: 12px;
      }
    }
    label {
      font-size: 16px;
      font-weight: 400;
      color: $sidebar-dashboard-text;
      margin-left: 12px;
      input {
        margin: 0 !important;
      }
    }
    &-select {
      border-radius: $border-radius;
      width: 100%;
      height: auto;
      padding: 6px 12px;
      font-size: 14px;
      border: 1px solid $basic-color-borderInp;
    }
  }
  &_buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0;
    &-save {
      @include buttonCustom(130, 40, 16);
      border: 1px solid $basic-color-violet;
    }
    &-close {
      width: 130px;
      height: 40px;
      font-size: 16px;
      background-color: transparent;
      border-radius: $border-radius;
      margin-bottom: 10px;
      color: $basic-color-violet;
      border: 1px solid $basic-color-violet;
      margin-left: 20px;
      &:hover {
        background-color: $basic-color-text-title;
        border: 1px solid transparent;
        color: $white;
      }
    }
    &-createRules {
      width: 130px;
      height: 40px;
      font-size: 16px;
      background-color: $basic-color-violet;
      border-radius: $border-radius;
      margin-bottom: 10px;
      color: $white;
      border: 1px solid $basic-color-violet;
      margin-left: 20px;
      &:hover {
        background-color: $sidebar-dashboard-text;
        border: 1px solid transparent;
      }
    }
  }
  &_valueBlock {
    margin-top: 20px;
    &-dropdown {
      width: 32%;
      height: 36px;
      border: 1px solid $basic-color-borderInp;
      border-radius: $border-radius;
      background-color: transparent;
      font-size: 14px;
      font-weight: 400;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      &:hover {
        border-color: $basic-color-violet;
      }
      &_button {
        width: 100%;
        position: relative;
        background-color: transparent;
        text-align: left;
        color: $basic-color-borderInpPlaceholder;
        &-img {
          position: absolute;
          right: 0;
          top: 5px;
        }
      }
    }
    &-list {
      width: 36.3%;
      &_li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 36px;
        margin: 10px 0;
        position: relative;
        &-text {
          width: 100%;
          height: 100%;
          border: 1px solid $basic-color-borderInp;
          border-radius: $border-radius;
          background-color: transparent;
          font-size: 14px;
          font-weight: 400;
          padding: 0 12px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        &-remove {
          margin-left: 15px;
          cursor: pointer;
        }
      }
    }
  }
  &_defaultValueBlockForBool {
    &-label {
      display: flex;
      align-items: center;
      &:first-child {
        margin-bottom: 10px;
      }
    }
    &-radio {
      margin-right: 12px;
      input {
        margin-top: 0;
      }
    }
  }
}
