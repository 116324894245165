.documents_wrapper{
    display: flex;

    .document{
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 50%;

        .title{
            font-size: 20px;
        }
        .img_wrapper{
            width: 80%;

            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }   
    }
}