@import "../_variables";

.poll {
  .categories-create_block-buttons button {
    margin: 0;
  }

  &-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &_add {
      width: auto;
    }
  }

  &-tools {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    &_left {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      flex-wrap: wrap;
      &-title,
      &-search {
        margin-right: 20px;
      }
    }
    &_center {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      &-title {
        font-size: 12px;
        font-weight: 400;
      }
      &-button {
        width: auto;
        height: 35px;
        font-size: 14px;
        font-weight: 400;
        margin: 0 15px 0 0;
      }
      &-label {
        max-height: max-content;
      }
      &-select {
        width: 280px;
      }
    }
    &_right {
      height: 100%;
      display: flex;
      align-items: flex-end;
    }
  }

  &-content {
    width: 100%;
    margin-top: 20px;
    &_li {
      width: 100%;
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      padding: 15px;
      margin-bottom: 20px;
      &-left,
      &-center,
      &-right {
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;
        position: relative;
        &_date {
          font-size: 12px;
          font-weight: 400;
          color: $basic-color-borderInpPlaceholder;
        }
        &_title {
          font-size: 32px;
          font-weight: 500;
          color: $basic-color-violet;
        }
        &_text {
          font-size: 12px;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
        }
      }
      &-right {
        &_title {
          span {
            font-size: 20px;
            font-weight: 500;
            color: rgba(102, 102, 102, 1);
          }
        }
        &_date {
          img {
            position: absolute;
            right: 5px;
            top: -5px;
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  &-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-create {
    &_content {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      &_block {
        width: 100%;
        border-radius: 8px;
        border: 1px solid $basic-color-violet;
        padding: 20px;
        .add-edit-htmls_center {
          &:last-child {
            margin-bottom: 0;
          }
        }
        &-question {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          .add-edit-htmls_center {
            margin: 0;
          }
          &_select {
            padding: 8px 12px;
          }
          &_image {
            margin: 0 20px 10px 20px;
          }
          &_bottom {
            border-top: 1px solid rgba(171, 167, 175, 1);
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
            width: 100%;
            &-wrapper {
              margin-top: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              &_block {
                width: max-content;
                border-right: 1px solid rgba(171, 167, 175, 1);
                &-icon {
                  margin: 10px 20px 10px 20px;
                  cursor: pointer;
                }
              }
              &_switch {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: 400;
                margin-left: 20px;
                position: relative;
                cursor: default;
                &-inp {
                  margin-left: 5px;
                  input {
                    width: 3em !important;
                    height: 30px;
                    border: 1px solid $basic-color-violet;
                    cursor: pointer !important;
                    &:checked {
                      background-color: $basic-color-violet;
                      border: none;
                    }
                  }
                }
                &_dots {
                  margin: 0 20px;
                  background-color: transparent;
                  img {
                    transform: scale(1.2);
                  }
                }
              }
            }
          }
        }
        &_tools {
          width: max-content;
          height: max-content;
          border: 1px solid $basic-color-violet;
          border-radius: 8px;
          margin-left: 10px;
          margin-bottom: 5px;
          &-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 3px;
            &_li {
              &-button {
                background-color: transparent;
                padding: 8px;
                border: 1px solid transparent;
                border-radius: 8px;
                position: relative;
                &:hover {
                  border: 1px solid $basic-color-violet;
                }
                &_icon button[title]:hover::after {
                  background-color: pink;
                }
              }
            }
          }
        }
      }
    }
  }
}
