.page{
    position: relative;

    .header{
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .lang_bar{
            margin-top: 20px;
            display: flex;
            gap: 10px;
            z-index: 100;
            button{
                padding: 5px 15px;
                width: fit-content;
                height: fit-content;
            }
        }
        .buttons{
            display: flex;
            gap: 20px;
        }
    }
    .content_wrapper{
        background: #FFF;
        padding: 10px;
        border: 1px #CCC solid;
        border-radius: 8px;
        margin-bottom: 36px;
    }
    .text_fileds_block{
        margin-bottom: 30px;
    }

    .content{
        display: flex;
        margin-top: 20px;
        .left{
            width: 50%;
            .textarea{
                width: 100%;
                height: 1000px;
                padding: 15px;
            }
        }
        .right{
            width: 50%;
        }
    }
}