.table_header{
    display: flex;
    gap: 15px;
    flex-direction: column;
    
    .search_row{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .info_row{
        font-size: 14px;
        display: flex;
        gap: 10px;
    }
}

.table{
    width: 100%;
    border-collapse: collapse;

    thead{
        border-top: 1px solid #c2c2c2;
        border-bottom: 1px solid #c2c2c2;
    }

    td{
        width: 12%;
        padding: 0px 5px;

        .button_wrapper{
                width: 22px;
                height: 22px;
                cursor: pointer;
            svg{
                width: 22px;
                height: 22px;
            }
        }
    }

    .checkbox_td{
        width: 3%;
    }

    .company_td{
        width: 40%;
    }

    .menu_td{
        width: 5%;
    }
}