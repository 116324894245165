@import "../_variables";

.advertising {
  width: 100%;
  .categories-create_block {
    max-width: 476px;
  }
  &-block {
    max-width: 476px;
    width: 100%;
    height: max-content;
    border: 1px solid $basic-color-borderInpPlaceholder;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
    &_administrator {
      &-list {
        margin: 10px 0;
        max-height: 300px;
        overflow: auto;
        &_li {
          margin: 8px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 3px;
          img {
            cursor: pointer;
          }
        }
      }
      &-inp {
        margin-top: 10px;
        .template-content_nameBlock-inp_psevdo {
          margin-bottom: 20px;
        }
        .template-content_buttons-save {
          img {
            margin-right: 5px;
          }
        }
      }
    }
  }
  &_media {
    width: 100%;
    height: max-content;
    border: 1px solid $basic-color-borderInpPlaceholder;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
    &-subtitle {
      font-weight: 400;
      font-size: 14px;
    }
    &-wrapper {
      width: 100%;
      padding-top: 30px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      &_image {
        width: 300px;
        height: 600px;
        border: 1px dashed $basic-color-borderInpPlaceholder;
        border-radius: 4px;
        position: relative;
        &-addImage {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: relative;
          &_file {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 1;
            opacity: 0;
          }
          &_photo {
            width: 100%;
            height: 100%;
            position: relative;
            &-img {
              width: inherit;
              height: inherit;
            }
            &-del {
              position: absolute;
              right: 0;
              top: 0;
              padding: 5px;
              background-color: $white;
            }
          }
          &_img {
            width: 100px;
            height: 100px;
          }
          &_button {
            width: 85%;
            margin: 20px 0;
          }
        }
        &:nth-child(2) {
          width: 920px;
          height: 132px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex-direction: row;
          margin: 0 10px;
          .advertising_media-wrapper_image-addImage {
            &_button {
              width: 200px;
            }
          }
        }
        &:nth-child(3) {
          height: 300px;
        }
      }
    }
    &-link {
      max-width: 350px;
      margin: 30px 0;
      p {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
    &-bigImg {
      &_image {
        border: 1px dashed $basic-color-borderInpPlaceholder;
        border-radius: 4px;
        position: relative;
        padding: 20px;
        .advertising_media-wrapper_image-addImage_photo {
          img {
            max-height: 300px;
          }
        }
        &-addImage {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: relative;
          &_file {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 1;
            opacity: 0;
          }
          &_img {
            width: 100px;
            height: 100px;
          }
          &_button {
            width: 210px;
            margin: 20px 0;
          }
        }
      }
    }
    &-footer {
      &_content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 5px;
        width: 350px;
      }
      &_text {
        white-space: nowrap;
        padding: 8px 12px;
        border: 1px solid $basic-color-borderInpPlaceholder;
        margin-left: 10px;
      }
    }
  }
}
