@import "../_variables";
.changeUnitForPrice {
  background-color: rgba(127, 127, 127, 0.5);
  position: fixed;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  z-index: 9999;
  overflow: hidden;
  @include centering;
  opacity: 0;
  animation: animationUnitForPriceModal ease 1s forwards;
  &-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: fit-content;
    overflow: auto;
  }
}
@keyframes animationUnitForPriceModal {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.changeUnitForTemplates {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
  &-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99;
    background-color: $basic-color-borderInpPlaceholder;
    &_wrapper {
      position: fixed;
      scroll-behavior: smooth; 
      height: 95%;
      width: 95%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: auto;
    }
  }
}
