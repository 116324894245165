@import "../_variables";
.users {
  &-wrapper {
    &_tabl {
      &-head {
        border-top: 1px solid $table-border-color;
        border-bottom: 1px solid $table-border-color;
      }
      &-trow {
        &_item {
          max-width: 200px;
          // min-width: 150px
          width: auto;
          position: relative;
          font-size: 16px;
          font-weight: 400;
          font-family: $font-fontDefault-Popins;
          //padding: 12px 8px !important
          white-space: nowrap;
          span {
            width: 90%;
            white-space: nowrap;
          }
          img {
            //position: absolute
            right: 12px;
            margin-left: 9px;
          }
          &:last-child {
            img {
              //position: absolute
              //right: 40px
              top: 7px;
            }
          }
          &:nth-child(1) {
            cursor: pointer;
            span {
              cursor: pointer;
            }
          }
          &:nth-child(2) {
            cursor: pointer;
            span {
              cursor: pointer;
            }
          }
          &:nth-child(3) {
            cursor: pointer;
            span {
              cursor: pointer;
            }
          }
          &:nth-child(4) {
            cursor: pointer;
            span {
              cursor: pointer;
            }
          }
          &:nth-child(5) {
            cursor: pointer;
            span {
              cursor: pointer;
            }
          }
          &:nth-child(6) {
            cursor: pointer;
            // text-align: center
            span {
              cursor: pointer;
            }
          }
          &:nth-child(7) {
            cursor: pointer;
            // text-align: center
            span {
              cursor: pointer;
            }
          }
          &:nth-child(8) {
            cursor: pointer;
            // text-align: center
            span {
              cursor: pointer;
            }
          }
          &:nth-child(9) {
            cursor: pointer;
            span {
              cursor: pointer;
            }
          }
          &:nth-child(10) {
            cursor: pointer;
            text-align: center;
            span {
              cursor: pointer;
            }
          }
        }
      }
      &-body {
        position: relative;

        &_item {
          max-width: 200px;
          // min-width: 150px
          width: auto;
          //padding: 20px 12px !important
          position: relative;

          &:nth-child(2) {
            p {
              cursor: pointer;
            }
          }
          p {
            font-weight: 300;
            font-family: $font-fontDefault-Popins;
            line-height: 148%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 95%;
          }
          &-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 3px;
            position: relative;
            &_icons {
              display: flex;
              align-items: center;
              justify-content: center;
              //min-width: 60px
              //max-width: 100px
              //position: absolute
              //right: 20px
              button {
                background-color: transparent;
                margin-left: 10px;
              }
            }
            &_input {
              margin-bottom: 2px;
              margin-right: 4px;
            }
            span {
              font-size: 13px;
              padding: 4px 8px;
              font-weight: 400;
              white-space: nowrap;
              border-radius: $border-radius;
            }
          }
        }
      }
    }
  }
}
.userInfo {
  background-color: $white;
  padding: 30px;
  position: relative;
  &-backButton {
    position: absolute;
    right: 0;
    top: -40px;
  }
  &-wrapper {
    padding: 20px 20px 10px 20px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    &_block {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      border-bottom: 3px solid $basic-color-violet;
      padding-bottom: 10px;
      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      &-avatar {
        &_img {
          width: 67px;
          max-width: 67px;
          height: 67px;
          min-height: 67px;
          border-radius: 50%;
          background-color: transparent;
          margin-right: 20px;
          //object-fit: contain
          object-fit: cover;
        }
      }
      &-pib {
        &_status {
          width: min-content;
          font-size: 13px;
          font-weight: 400;
          background-color: rgba(45, 243, 28, 1);
          padding: 3px 6px;
          white-space: nowrap;
        }
        &_name {
          font-size: 20px;
          line-height: 30px;
          font-weight: 400;
          margin: 0;
        }
        &_email {
          font-size: 10px;
          font-weight: 300;
          line-height: 15px;
        }
      }
      &-buttons {
        @include centering;
        &_message {
          width: 116px;
          height: 28px;
          @include centering;
          background-color: $basic-color-violet;
          color: $white;
          border: 1px solid transparent;
          padding: 6px;
          font-size: 14px;
          font-weight: 400;
          border-radius: 4px;
          margin-right: 10px;
          &:hover {
            background-color: $basic-color-text-title;
            border: 1px solid transparent;
            color: $white;
          }
        }
        &_block {
          width: 116px;
          height: 28px;
          @include centering;
          background-color: transparent;
          color: $userInfo-red-button;
          border: 1px solid $userInfo-red-button;
          padding: 6px;
          font-size: 14px;
          font-weight: 400;
          border-radius: 4px;
          &:hover {
            background-color: $basic-color-text-title;
            border: 1px solid transparent;
            color: $white;
          }
        }
      }
    }
  }
  &-tabs {
    margin-top: 30px;
    .nav {
      --bs-nav-link-color: $sidebar-dashboard-text !important;
      --bs-nav-tabs-border-width: 0;
    }
    &_block {
      border-bottom: 1px solid $basic-color-borderInp;
      --bs-nav-tabs-link-active-border-color: transparent;
      --bs-nav-tabs-link-hover-border-color: none;
      li {
        .nav-link.active {
          border-bottom: 3px solid $basic-color-violet !important;
        }
      }
      &-wrapper {
        margin-top: 20px;
        .table-responsive {
          height: max-content;
        }
      }
      &-title {
        font-size: 20px;
        font-weight: 400;
        line-height: 28.8px;
      }
      &-content {
        margin-top: 10px;
        &_line {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 40px;
          margin: 5px 0;
          &-title {
            width: 150px;
            display: flex;
            align-items: center;
          }
          &-text {
            width: 380px;
            padding: 8px 12px;
            border: 1px solid $basic-color-borderInp;
            display: flex;
            align-items: center;
            min-height: 35px;
          }
        }
      }
      &-balance {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: rgba(243, 247, 246, 1);
        padding: 20px;
        max-width: 960px;
        &_text {
          font-weight: 400;
          margin-right: 20px;
          span {
            font-size: 13px;
          }
          p {
            font-size: 13px;
            margin-top: 5px;
            b {
              font-size: 24px;
              font-weight: 500;
            }
          }
        }
        &_label {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          input {
            min-width: 545px;
            width: 100%;
          }
          button {
            margin-left: 20px;
          }
        }
      }
      &-documents {
        &_title {
          font-size: 20px;
          font-weight: 400;
          line-height: 29px;
          margin: 10px 0;
        }
        &_photos {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          &_img {
            max-width: 400px;
            min-width: 200px;
            max-height: 400px;
            min-height: 200px;
            width: auto;
            height: auto;
            margin: 0 5px 10px 0;
            border-radius: 8px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        &_buttons {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          &-but {
            &:nth-child(2) {
              width: 116px;
              height: 28px;
              @include centering;
              background-color: $white;
              color: $basic-color-violet;
              border: 1px solid $basic-color-violet;
              padding: 6px;
              font-size: 14px;
              font-weight: 400;
              border-radius: 4px;
              margin-left: 10px;
              &:hover {
                background-color: $basic-color-text-title;
                border: 1px solid transparent;
                color: $white;
              }
            }
          }
        }
      }
      &-history {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 20px 0;
        &_label {
          margin-right: 10px;
          font-size: 14px;
          font-weight: 400;
        }
        &_select {
          width: 228px;
        }
      }
      &-table {
        &_head {
          border-bottom: 1px solid $basic-color-borderInp;
          border-top: 1px solid $basic-color-borderInp;
        }
        &_body {
          p {
            margin: 5px 0;
            font-weight: bold;
          }
        }
      }
      &-chat {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        &_block {
          max-width: 350px;
          max-height: 670px;
          overflow: auto;
        }
        &_li {
          padding: 10px;
          border-bottom: 1px solid $basic-color-borderInp;
          margin-bottom: 10px;
          &-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &_avatar {
              margin-right: 10px;
              &-img {
                width: 40px;
                height: 40px;
                background-color: #999;
                border-radius: 50%;
              }
            }
            &_content {
              max-width: 325px;
              font-size: 14px;
              font-weight: 400;
              &-adverts {
                font-weight: 800;
                margin: 4px 0;
              }
              &-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            &_time {
              padding-right: 5px;
            }
          }
        }
        &_messages {
          max-height: 650px;
          overflow: auto;
          //min-height: 970px
          //background-color: pink
          padding: 20px;
          &-content {
            margin: 10px 0;
            &_wrapper {
              padding: 10px;
            }
          }
        }
      }
    }
  }
  &-agreement {
    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &_close {
      @include buttonCustom(40, 40, 16);
      margin-bottom: 20px;
      background-color: $white;
      color: $basic-color-violet;
      border: 1px solid $basic-color-violet;
      img {
        margin: 0;
      }
    }
    &_title {
      font-size: 30px;
      font-weight: 700;
      color: rgba(153, 153, 153, 1);
    }
    &_wrapper {
      border: 1px solid rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      padding: 20px;
      margin-top: 20px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      &-block {
        min-width: 340px;
        &:first-child {
          width: 70%;
        }
        &:last-child {
          width: 30%;
        }
      }
      &-content,
      &-delivery {
        &_li {
          margin-bottom: 20px;
          &-title {
            font-size: 14px;
            font-weight: 600;
          }
          &-content {
            width: 100%;
            min-height: 26px;
            font-size: 14px;
            font-weight: 400;
            margin-top: 4px;
            margin-bottom: 10px;
            border: 1px solid $basic-color-borderInpPlaceholder;
            padding: 4px 12px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
      &-delivery {
        border: 1px solid rgba(171, 167, 175, 1);
        padding: 20px;
        &_li {
          &-title {
            font-weight: 400;
            font-size: 12px;
            b {
              font-size: 14px;
              font-weight: 600;
            }
          }
          &-content {
            &-grey {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              background-color: rgba(245, 243, 247, 1);
              padding: 3px 8px;
              img {
                margin-right: 5px;
              }
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &-history {
        border: 1px solid rgba(171, 167, 175, 1);
        padding: 20px;
        &_li {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          &-title {
            &:first-child {
              margin-right: 20px;
            }
          }
          h4 {
            font-size: 14px;
            font-weight: 600;
          }
          &-title {
            font-weight: 400;
            font-size: 12px;
            margin: 20px 0;
          }
        }
      }
    }
    &_agent {
      margin-left: 20px;
    }
  }
}
.right {
  width: 100%;
  max-width: 700px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  background-color: $white;
  &:after {
    content: "";
    width: 12px;
    height: 12px;
    //border-left: 10px solid transparent
    //border-right: 10px solid transparent
    //border-top: 10px solid rgba(39,174,96, 0.3)
    position: absolute;
    right: 2.5px;
    bottom: -6px;
    transform: rotate(-140deg);
    border: 1px solid $basic-color-violet;
    border-width: 1px 0 0 1px;
    z-index: 1;
    background-color: $white;
  }
  .userInfo-tabs_block-chat_messages-content_wrapper {
    width: 70%;
    border: 1px solid $basic-color-violet;
    border-radius: 15px 15px 0 15px;
  }
}
.left {
  width: 100%;
  max-width: 700px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  &:after {
    content: "";
    width: 12px;
    height: 12px;
    //border-left: 10px solid transparent
    //border-right: 10px solid transparent
    //border-top: 20px solid	#999
    transform: rotate(-140deg);
    border: 1px solid $basic-color-borderInpPlaceholder;
    border-width: 1px 0 0 1px;
    position: absolute;
    left: 2.5px;
    bottom: -6px;
    z-index: 1;
    background-color: $white;
  }
  .userInfo-tabs_block-chat_messages-content_wrapper {
    width: 70%;
    border-radius: 10px 10px 10px 0;
    border: 1px solid $basic-color-borderInpPlaceholder;
  }
}
.id-size {
  width: 75px !important;
  .users-wrapper_tabl-body_item-wrapper {
    max-width: 150px;
    width: auto;
    span {
      white-space: nowrap;
      text-overflow: ellipsis !important;
      overflow: hidden;
    }
  }
}
.login-size {
  max-width: 100px;
  width: auto;
  span {
    white-space: nowrap;
    text-overflow: ellipsis !important;
    overflow: hidden;
  }
}
.phone-size {
  max-width: 60px;
  width: auto;
  span {
    white-space: nowrap;
    text-overflow: ellipsis !important;
    overflow: hidden;
  }
}
