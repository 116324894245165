.other_statistics {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 767.98px) {
    margin-top: 36px;
  }

  @media (max-width: 639.98px) {
    margin-top: 24px;
  }

  .statistic_row {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .title {
      font-size: 24px;
      font-weight: 400px;

      @media (max-width: 639.98px) {
        font-size: 16px;
      }
    }
  }
}
