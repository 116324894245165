.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 16px;
    background-color: #fff;
    box-shadow: 1px 5px 17.3px 0px rgba(0, 0, 0, 0.1019607843);
    justify-content: center;

    .row{
        display: flex;
        align-items: center;

        .row_column{
            display: flex;
            flex-direction: column;
            gap: 20px;
    
            .text_wrapper{
                width: 33%;
                display: flex;
                flex-direction: column;
                gap: 4px;
        
                .title{
                    margin-left: 7px;
                }
                .value{
                    border: 1px #CCC solid;
                    padding: 8px 12px;
                    border-radius: 4px;
                    min-width: 300px;
                    color: #444444;
                }
            }
        }
    }
    
    .buttons_wrapper{
        display: flex;
        gap: 15px;

        .success_button{
            padding: 8px 28px;
            background: #FF6600;
            color: #FFF;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 5px;

            svg{
                width: 24px;
                height: 24px;
            }

            span{
                cursor: pointer;
            }
        }

        .reject_button{
            background: none;
            color: #FF6600;
            border: 1px #FF6600 solid;

            svg{
                width: 16px;
                height: 16px;
            }
        }
    }

    .logo_wrapper{
        width: 192px;
        height: 108px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.markers_wrapper{
    display: flex;
    gap: 20px;
    margin-top: 50px;
    flex-wrap: wrap;
    justify-content: space-evenly;
}