@import "../_variables";
.statistic {
  padding: 30px 30px 20px 30px;
  font-family: $font-fontDefault-Nunito;
  display: flex;
  flex-direction: column;

  @include mobile {
    padding: 16px 16px 8px 16px;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    @include mobile {
      flex-direction: column;
      margin-bottom: 16px;
    }
    &_right{
      display: flex;
      align-items: center;
      gap: 50px;

      &_languages_wrapper{
        border: 1px #FF6600 solid;
        border-radius: 4px;
        position: relative;
        padding: 5px;
        align-self: flex-start;

        &_current_lang{
          color: #FF6600;
          font-weight: 500;
          font-size: 16px;
          cursor: pointer;
          z-index: 2;
          text-transform: uppercase;
        }

        &_close_wrapper{
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          position: fixed;
          z-index: 2;
          opacity: 0;
          visibility: hidden;
        }

        &_dropdown_wrapper{
          position: absolute;
          top: 110%;
          left: 50%;
          transform: translate(-50%, 0%);
          box-shadow: 15px 15px 35px #3e134d26;
          display: flex;
          flex-direction: column;
          background: #FFF;
          z-index: 3;
          overflow: hidden;
          transition: .2s;
          border-radius: 3px;
          height: 0px;

          &_item{
            font-size: 16px;
            text-transform: uppercase;
            transition: .1s;
            cursor: pointer;
            padding: 7px 5px;
            border-radius: 3px;

            &:hover{
              color: #FFF;
              background: #FF6600;
            }
          }
        }
      }

      &_date {
        margin-right: 50px;
  
        @include mobile {
          margin-right: 0px;
        }
  
        &-calendar {
          font-weight: 700;
          font-size: 24px;
          text-align: right;
          color: #FF6600;
          border-bottom: 1px #cecece solid;
          text-align: center;
          padding-bottom: 10px;
  
          @include tablet {
            font-size: 16px;
            padding-bottom: 4px;
          }
        }
  
        &-time {
          font-weight: 400;
          font-size: 19px;
          text-align: right;
          margin-top: 10px;
  
          @include tablet {
            font-size: 14px;
          }
        }
      }
    }
  }
  &-analytics {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    min-height: 400px;

    &_title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      padding-left: 10px;

      &-info {
        position: absolute;
        right: -20px;
        top: -8px;
      }
    }
    &_wrapper {
      width: 100%;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      font-family: $font-fontDefault-Nunito;
      overflow-x: auto;
      padding: 10px;

      &-block {
        width: fit-content;
        min-height: 90px;
        height: 90px;
        background-color: $white;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 5px 0;
        cursor: pointer;
        box-sizing: border-box;
        border: 2px transparent solid;
        transition: 0.2s;
        &:hover {
          border: 2px #cad1e1 solid;
        }

        &_left {
          height: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-around;
          flex-direction: column;
          padding: 0 10px;
          cursor: pointer;

          &-title {
            font-weight: 700;
            font-size: 20px;
            color: $moderation-payment-text;
            cursor: pointer;
            user-select: none;
          }
          &-number {
            display: flex;
            align-items: flex-end;
            cursor: pointer;

            &_img {
              margin-right: 6px;
              padding-bottom: 3px;
            }
            &_big {
              font-weight: 600;
              font-size: 32px;
              cursor: pointer;
              user-select: none;
            }
            &_percent {
              font-weight: 600;
              font-size: 14px;
              color: $green;
              padding-bottom: 3px;
              cursor: pointer;
            }
          }
        }
        &_right {
          height: 100%;
          display: flex;
          align-items: flex-start;
          cursor: pointer;
        }

        &_toggles {
          @include centering;

          &-buttons {
            margin-right: 20px;
            border: 1px solid $basic-color-borderInpPlaceholder;
            border-radius: 4px;

            &_btn {
              width: 100px;
              height: 28px;
              background-color: $white;
              border-radius: 4px;
              color: $moderation-payment-text;
              transition: 0.5s;
            }
          }
          &-calendar {
            height: 30px;
            @include centering;
            border: 1px solid $basic-color-borderInpPlaceholder;
            border-radius: 4px;
            background-color: $white;
            padding: 0 10px;
            color: $moderation-payment-text;

            &_icon {
              margin-left: 12px;
            }
          }
        }
      }
    }
    &_graph_wrapper {
      white-space: pre-wrap;
      min-height: 400px;
      background: #fff;
      position: relative;

      &_loading_wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        svg {
          width: 50px;
          height: 50px;
        }
      }
    }
    &_categoryInfo {
      width: 100%;
      background-color: $white;
      border-radius: 4px;

      &-wrapper {
        padding: 30px;

        &_ul {
          width: 100%;
          font-weight: 500;
          font-size: 20px;

          &-li {
            height: 47px;
            width: inherit;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:first-child {
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }
    }
    &_map,
    &_referrals {
      width: 100%;
      background-color: $white;
      border-radius: 4px;

      &-wrapper {
        width: 100%;
        margin: 0 auto;
        padding: 30px;
        position: relative;
        @include centering;
        flex-direction: column;

        &_gradient {
          position: absolute;
          left: 20px;
          bottom: 20px;

          &-item {
            margin: 10px 0;

            &_text {
              font-size: 14px;
              font-weight: 500;
              position: relative;

              span {
                margin-left: 25px;
              }
              &:after {
                content: "";
                width: 18px;
                height: 18px;
                border-radius: 50%;
                position: absolute;
                left: 0;
                bottom: 0;
              }
            }
            &:nth-child(1) {
              .statistic-analytics_map-wrapper_gradient-item_text {
                &:after {
                  background-color: rgba(102, 92, 209, 1);
                }
              }
            }
            &:nth-child(2) {
              .statistic-analytics_map-wrapper_gradient-item_text {
                &:after {
                  background-color: rgba(149, 140, 248, 1);
                }
              }
            }
            &:nth-child(3) {
              .statistic-analytics_map-wrapper_gradient-item_text {
                &:after {
                  background-color: rgba(194, 188, 255, 1);
                }
              }
            }
            &:nth-child(4) {
              .statistic-analytics_map-wrapper_gradient-item_text {
                &:after {
                  background-color: rgba(225, 222, 255, 1);
                }
              }
            }
          }
        }
        &_control {
          width: 440px;
          border: 1px solid $basic-color-borderInpPlaceholder;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;

          &-btn {
            width: max-content;
            height: 28px;
            background-color: $white;
            border-radius: 4px;
            color: $moderation-payment-text;
            padding: 0 12px;
            &:first-child {
              background-color: $basic-color-violet;
              color: $white;
              border: 2px solid $white;
            }
          }
        }
      }
    }
    &_referrals {
      &-wrapper {
        &_graph {
          margin-top: 80px;
          margin-bottom: 30px;
          width: 714px;
        }
        .statistic-analytics_map-wrapper_control {
          width: auto;
        }
      }
    }
    &_graph_wrapper {
      padding: 20;
      background: #fff;
      width: 100%;
      border-radius: 8px;
    }
  }
  &-footer {
    @include centering;
    margin-top: 50px;
    img {
      max-width: 50px;
    }
    &_text {
      margin-left: 15px;
      font-size: 19px;
      font-weight: 400;

      @include tablet {
        font-size: 14px;
      }

      @include mobile {
        font-size: 12px;
      }
    }
  }
}
.active-statistic-block {
  border: 2px #cad1e1 solid;
  box-shadow: 0 0 8px 0 #FF6600;
}
.active-filter-buttons {
  color: #FF6600;
  font-weight: 600;
}

.statistic-header_date-users {
  color: #555;

  @include tablet {
    margin-top: 4px;
  }
}
