.modal_wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 6px 26px #00000026;
    background-color: #fff;
    padding: 15px;
    border-radius: 6px;
    z-index: 100;
    display: flex;
    flex-wrap: wrap;
    max-width: 50%;
    gap: 7px;
    span{
        border-bottom: 1px #CCC solid;
    }
}