.page{
    display: flex;
    flex-direction: column;
    gap: 15px;

    .header{
        box-shadow: 0px 2px 8px 0px #00000029;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 8px 10px;

        .content{
            display: flex;
            justify-content: space-between;

            .left{
                display: flex;
                gap: 20px;

                .user_wrapper{
                    display: flex;
                    gap: 15px;
            
                    .logo{
                       width: 67px;
                       height: 67px;
                       position: relative;
                       
                       img{
                            width: 100%;
                            height: 100%; 
                            object-fit: contain;
                       }
                    }
            
                    .user_info{
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
            
                        .name{
                            font-size: 20px;
                        }
            
                        .email{
                            font-size: 12px;
                        }
                    }
                }
                .balance_wrapper{
                    display: flex;
                    flex-direction: column;
                    padding: 10px 16px;
                    background: #EAE9F9;
                    border-radius: 4px;
                    gap: 5px;
        
                    img{
                        width: 115px;
                        height: 17px;
                    }
        
                    .balance{
                        display: flex;
                        gap: 5px;
                        align-items: flex-end;
        
                        .count{
                            font-size: 24px;
                            font-weight: 500;
                            color: #FF6600;
                        }
                        .valute{
                            font-size: 13px;
                            transform: translateY(-3px);
                            color: #FF6600;
                        }
                    }
                }
            }
            
            .status{
                width: fit-content;
                padding: 4px 8px;
                background: #6B778C;
                border-radius: 2px;
                font-size: 13px;
                color: #FFF;
            }

            .right{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                .button_wrapper{
                    margin-bottom: 0px;

                    .banned{
                        background: none;
                        border: 1px #c71a0a solid;
                        font-weight: 600;
                        color: #b64a41;
                        padding: 10px 20px;
                        font-size: 13px;
                        border-radius: 3px;
                        cursor: pointer;
                        transition: .2s;

                        &:hover{
                            background: #d12b1c;
                            color: #FFF;
                        }
                    }
                    .unbanned{
                        background: none;
                        border: 1px #00A046 solid;
                        color: #00A046;
                        padding: 10px 20px;
                        font-weight: 600;
                        font-size: 13px;
                        border-radius: 3px;
                        cursor: pointer;
                        transition: .2s;

                        &:hover{
                            background: #00A046;
                            color: #FFF;
                        }
                    }
                }
            }
            
            .moderation{
                background: #6B778C;
                color: #FEFEFE;
            }

            .active{
                background: #00A046;
                color: #FFF;
            }

            .rejected{
                background: #FF791F;
                color: #FFF;
            }

            .banned{
                background: #FF4838;
                color: #FFF;
            }

            .removed{
                background: #272727;
                color: #FFF;
            }
        }
    
        .line{
            width: 100%;
            height: 3px;
            background: #FF6600;
            border-radius: 3px;
        }
    }
}