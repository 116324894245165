@import "../_variables";

.subscription {
  &-button_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &-wrapper {
    padding: 20px 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    &_block {
      width: 335px;
      min-width: 272px;
      height: max-content;
      min-height: auto;
      border: 3px solid rgba(241, 240, 255, 1);
      border-radius: 15px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      padding: 20px 15px 20px 20px;
      margin: 10px 20px 10px 0;
      &-top {
        width: 100%;
      }
      &-header {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &_h3 {
          font-weight: 400;
          font-size: 24px;
          color: $basic-color-violet;
          line-height: 35px;
          font-family: $font-fontDefault-Dela;
          text-align: center;
          background-color: $white;
          z-index: 2;
          padding: 0 8px;
          margin: 0;
        }
        &_line {
          width: 100%;
          height: 2px;
          background-color: $basic-color-violet;
          position: absolute;
          left: 0;
          z-index: 1;
        }
      }
      &-coast {
        font-weight: 700;
        font-size: 24px;
        text-align: center;
        margin: 20px 0;
      }
      &-period {
        &_title {
          font-weight: 700;
          font-size: 16px;
        }
        &_checkbox {
          font-weight: 500;
          font-size: 16px;
          .form-check-input:disabled ~ .form-check-label,
          .form-check-input[disabled] ~ .form-check-label {
            opacity: 1;
          }
          input:checked {
            background-color: $basic-color-violet;
            border: none;
          }
          input:disabled {
            background-color: $basic-color-violet;
            border: none;
            opacity: 1;
          }
          &-notchacked {
            background-color: $white;
          }
        }
      }
      &-advantages {
        width: 100%;
        max-height: 320px;
        overflow: auto;
        padding-right: 5px;
        &_li {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;
          position: relative;
          &:after {
            width: 9px;
            height: 9px;
            content: "";
            position: absolute;
            left: 0;
            border-radius: 50%;
            border: 2px solid $basic-color-violet;
          }
          &-title {
            font-weight: 700;
            font-size: 16px;
            margin-top: 20px;
          }
          &-text {
            margin-left: 13px;
          }
        }
      }
      &-button {
        @include buttonCustom(290, 28, 16);
        margin-bottom: 0;
        margin-top: 20px;
      }
    }
  }
  &-option-wrapper {
    padding: 20px 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    &_block {
      width: fit-content;
      min-width: 272px;
      height: max-content;
      min-height: auto;
      border: 3px solid rgba(241, 240, 255, 1);
      border-radius: 15px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      padding: 20px 15px 20px 20px;
      margin: 10px 20px 10px 0;
      &-top {
        width: 100%;
      }
      &-header {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &_h3 {
          font-weight: 400;
          font-size: 24px;
          color: $basic-color-violet;
          line-height: 35px;
          font-family: $font-fontDefault-Dela;
          text-align: center;
          background-color: $white;
          z-index: 2;
          padding: 0 8px;
          margin: 0;
        }
        &_line {
          width: 100%;
          height: 2px;
          background-color: $basic-color-violet;
          position: absolute;
          left: 0;
          z-index: 1;
        }
      }
      &-advantages {
        width: 100%;
        overflow: auto;
        padding-right: 5px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 10px;
        &_li {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;
          position: relative;
          gap: 20px;
          &:after {
            width: 9px;
            height: 9px;
            content: "";
            position: absolute;
            left: 0;
            border-radius: 50%;
            border: 2px solid $basic-color-violet;
          }
          &-title {
            font-weight: 700;
            font-size: 16px;
            margin-top: 20px;
          }
          &-text {
            margin-left: 13px;
          }
          input {
            border: 1px #ccc solid;
            text-align: center;
            width: 70px;
            color: #FF6600;
          }
          svg {
            margin-left: 10px;
            cursor: pointer;
            transform: translateY(-3px);
          }
        }
      }
      &-add_icon {
        display: flex;
        justify-content: center;
        svg {
          width: 40px;
          height: 40px;
          cursor: pointer;
          path {
            fill: #FF6600;
          }
        }
      }
      &-button {
        @include buttonCustom(290, 28, 16);
        margin: 0 auto;
        margin-top: 20px;
      }
    }
  }
  &-create {
    &_wrapper {
      min-width: 515px;
      min-height: 408px;
      width: 515px;
      height: max-content;
      border: 1px solid $basic-color-borderInpPlaceholder;
      border-radius: 4px;
      padding: 20px;
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      input {
        &::placeholder {
          color: $basic-color-borderInpPlaceholder;
        }
      }
      &-date {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        &_block {
          width: 50%;
          &:first-child {
            margin-right: 5px;
          }
          .template-content_rules-select {
            margin-bottom: 0 !important;
            border-radius: 4px !important;
          }
          &-title {
            margin-bottom: 3px;
            position: relative;
            width: 100%;
            &:last-child {
              padding: 8px 12px;
              border-radius: 4px;
              border: 1px solid $basic-color-borderInpPlaceholder;
            }
            &-translation{
              position: absolute;
              top: -60%;
              right: 0px;
              width: 70px;
              height: 25px;
              cursor: pointer;
            }
            svg{
              position: absolute;
              top: -60%;
              right: 0;
              width: 25px;
              height: 25px;
              cursor: pointer;
            }
          }
        }
        &_save {
          padding: 8px 12px;
          background-color: $basic-color-violet;
          border: 1px solid $basic-color-violet;
          margin-top: 4px;
          color: $white;
          border-radius: 4px;
          &:hover {
            color: $white;
            background-color: $sidebar-dashboard;
          }
        }
      }
      &-preference {
        width: 100%;
        &_wrapper {
          width: inherit;
          &-li {
            width: inherit;
            min-height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            border-radius: 4px;
            border: 1px solid $basic-color-borderInpPlaceholder;
            padding: 5px 10px;
            &_input {
              max-width: 120px;
              &::placeholder {
                padding: 0;
              }
            }
          }
        }
      }
    }
    &_label {
      width: 100%;
      margin: 0 0 20px 0;
    }
    &_inp {
      &::placeholder {
        padding: 0;
      }
    }
    &_button {
      &:last-child {
        margin-left: 5px;
      }
    }
    &_plus {
      margin-top: 8px;
    }
  }
}
