.book_name{
  span{
    color: #00B998;
  }
}
.terms_wrapper{
  padding-top: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  header{
    .menu{
      display: flex;
      .item{
        padding: 15px 25px;
        font-size: 18px;
        line-height: 24px;
        cursor: pointer;
        border-bottom: 3px transparent solid;
        transition: .2s;
        user-select: none;
      }
      .active{
        border-bottom: 3px #FF6600 solid;
      }
    }
  }
}