.content{
    display: flex;
    flex-direction: column;
    gap: 20px;
    .book{
        border-radius: 8px;
        box-shadow: 0 0 10px 0 #00000040;
        display: flex;
        gap: 24px;
        padding: 12px 21px;
        width: 100%;
        .left{
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .title{
                color: #FF6600;
                font-size: 20px;
            }
            .date{
                font-size: 12px;
                display: flex;
                align-items: flex-end;
            }
        }
        .center{
            display: flex;
            flex: 1 0 auto;
            justify-content: space-around;
            .item{
                display: flex;
                flex-direction: column;
                gap: 10px;
                .top{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-bottom: 1px #CCC solid;
                    padding: 0 10px;
                    padding-bottom: 10px;
                    .value{
                    font-size: 32px;
                    color: #FF6600;
                    line-height: 42px;
                    position: relative;
                    width: fit-content;
            
                    .procent{
                        position: absolute;
                        bottom: 0;
                        left: 110%;
                        font-size: 14px;
                        color: #009B33;
                    }
                    }
                    .value_name{
                    transform: translateX(-10px);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    cursor: pointer;
                    }
                    .desc{
                    font-size: 14px;
                    color: #666666;
                    }
                    svg{
                        width: 30px;
                        height: 30px;
                        margin-bottom: 10px;
                    }
                }
                .status_on{
                    text-align: center;
                    color: green;
                    font-size: 10px;
                    letter-spacing: 3px;
                    font-weight: 700;
                    .progress{
                        margin-top: 5px;
                        display: block;
                    }
                }
                .status_off{
                    text-align: center;
                    color: red;
                    font-size: 10px;
                    letter-spacing: 3px;
                    font-weight: 700;
                    .progress{
                        margin-top: 5px;
                        display: block;
                    }
                }
                .status_check{
                    text-align: center;
                    color: rgb(192, 125, 0);
                    font-size: 10px;
                    letter-spacing: 3px;
                    font-weight: 700;
                    .progress{
                        margin-top: 5px;
                        display: block;
                    }
                }
                .status_accept{
                    text-align: center;
                    color: #0077c7;
                    font-size: 10px;
                    letter-spacing: 3px;
                    font-weight: 700;
                    .progress{
                        margin-top: 5px;
                        display: block;
                    }
                }
                .button{
                    padding: 7px 5px;
                    color: #FFF;
                    background-color: #FF6600;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    cursor: pointer;
                    position: relative;
                    svg{
                        path{
                            fill: #FFF !important;
                        }
                    }
                }
                .button_cancel{
                    padding: 7px 5px;
                    color: #FFF;
                    background-color: #CCC;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    cursor: pointer;
                }
                .button_accept{
                    padding: 7px 5px;
                    color: #FFF;
                    background-color: #0077c7;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    cursor: pointer;
                }
            }
        }
    }
}