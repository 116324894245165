@import "../_variables";
.sidebare {
  width: 220px;
  background-color: $white;
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  scrollbar-width: none;

  &-logo_wrapper{
    padding: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    img{
      max-height: 80px;
    }
  }

  &-block {
    padding: 25px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  &-dashboard {
    color: $sidebar-dashboard-text;
    flex: 1 0 auto;
    width: inherit;

    &_li {
      cursor: pointer;
      display: flex;
      flex-direction: column;

      &-wrapper {
        width: 100%;
        height: 50px;
        padding-left: 25px;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
      }
    }
    &_subnav {
      width: inherit;
      height: max-content;

      &-li {
        width: 100%;
        height: 50px;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0 15px;
        cursor: pointer;
        position: relative;

        &:hover {
          background-color: #FFF3EB;

          &:before {
            content: "";
            width: 5px;
            height: 100%;
            background-color: #FFC8A3;
            position: absolute;
            left: 0;
          }
        }
        &_img {
          margin-right: 10px;
        }
        &_title {
          cursor: pointer;
          font-size: 16px;
          font-weight: 300;
          svg{
            width: 16px;
            height: 16px;
            margin-left: 2px;
          }
        }
        &_counter {
          position: absolute;
          right: 20px;
          color: $white;
          background-color: $basic-color-violet;
          border-radius: 40px;
          padding: 3px 5px;
          font-size: 10px;
          font-weight: 700;
        }
      }
    }
    &_img {
      &-active * {
        fill: $sidebar-dashboard-activeIcon;
      }
    }
    &_title {
      font-size: 18px;
      font-weight: 300;
      color: $sidebar-dashboard;
      margin-left: 9px;
      cursor: pointer;
      display: flex;
      align-items: center;
      svg{
        width: 16px;
        height: 16px;
        margin-left: 2px;
      }
    }
    &_arrow {
      margin-left: 12px;
    }
  }
  &-buttonExit {
    background-color: transparent;
    @include centering;
    color: $basic-color-violet;
    font-weight: 400;
    font-size: 19px;
    line-height: 26px;
    margin: 70px 0 50px 18px;

    @include tabletMax {
      margin-top: 24;
    }

    &_img {
      margin-right: 8px;
    }
  }
  .accordion-button:focus {
    box-shadow: none;
  }
  .accordion-item {
    .accordion-header {
      .accordion-button:not(.collapsed) {
        position: relative;
        &:before {
          content: "";
          width: 5px;
          height: 100%;
          background-color: #FFC8A3;
          position: absolute;
          left: 0;
        }
      }
    }
  }
  .accordion-button:not(.collapsed) {
    .sidebare-dashboard_title{
      color: #FF6600 !important;
    }
    background-color: #FFF3EB;
    svg * {
      fill: #FF6600;
    }
  }
  .accordion-body {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }
  .accordion {
    --bs-accordion-border-width: 1px solid transparent !important;
    --bs-accordion-btn-icon-transform: rotate(0deg) !important;
  }
  .arrowHide {
    .accordion-button::after {
      display: none;
    }
  }
  .active-sidebar-show {
    position: relative;
    color: #FF6600;
    background-color: #FFF3EB;
    &:before {
      content: "";
      width: 5px;
      height: 100%;
      background-color: #FFC8A3;
      position: absolute;
      left: 0;
    }
    svg * {
      fill: #FF6600;
    }
  }
}
.accordion-button::after {
  transform: rotate(-90deg);
}
