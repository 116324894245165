@import "../_variables";
.footer {
  width: 100%;
  height: 26px;
  position: absolute;
  bottom: 0;
  &-wrapper {
    height: inherit;
    @include centering;
    &_img {
      margin-right: 20px;
    }
    &_text {
      font-size: 19px;
      font-weight: 400;
      text-align: center;
    }
  }
}
