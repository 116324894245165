@import "../_variables";

.noticeItem {
  width: 100%;
  background-color: $white;
  padding: 20px;
  overflow-y: auto;

  &-wrapper {
    background-color: $white;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;

    &_title {
      font-size: 14px;
      font-weight: 600;
    }
    &_general {
      
      min-width: 415px;
      // border: 2px solid $basic-color-borderInpPlaceholder;
      box-shadow: 0px 0px 20px -5px #CCC;
      border-radius: 8px;
      margin-top: 10px;
      padding: 10px;

      &-header {
        font-size: 12px;
        font-weight: 400;
      }
      &-text {
        width: 100%;
        min-height: 35px;
        font-size: 14px;
        font-weight: 400;
        margin-top: 4px;
        margin-bottom: 10px;
        border: 1px solid $basic-color-borderInpPlaceholder;
        border-radius: 4px;
        padding: 4px 12px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: auto;
        .CheckedIcon {
          white-space: nowrap;
          margin-right: 3px;
        }
        img {
          margin-right: 3px;
        }
      }
    }
    &_info {
      min-width: 693px;
      // border: 1px solid $basic-color-borderInpPlaceholder;
      box-shadow: 0px 0px 20px -5px #CCC;
      border-radius: 8px;
      margin-top: 10px;
      padding: 10px;

      &-images {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 20px;
        &_photo {
          margin: 3px;
          @include centering;
          border-radius: 4px;
        }
      }
    }
    &_status {
      margin: 10px;
      padding: 10px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      // flex-wrap: wrap;
      width: 100%;
      &-top{
        width: 50%;
      }
      &-block {
        min-width: 693px;
        margin-left: 20px;
        width: 50%;
      }
      &-author {
        padding: 20px;
        background-color: rgba(222, 219, 245, 1);
        border-radius: 8px;
        

        &_body {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          margin-top: 10px;
          min-height: 120px;

          &-wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
          }
          &-avatar {
            width: 64px;
            height: 64px;
            margin-right: 10px;
            border-radius: 50%;
            object-fit: cover;
          }
          &-content {
            &_name {
              margin-bottom: 5px;
            }
            &_subname {
              &-p {
                font-size: 14px;
                font-weight: 400;
                color: $sidebar-dashboard-text;
              }
              &-info {
                font-size: 16px;
                font-weight: 400;
                color: $creatorInfoColorText;
                margin-top: 3px;
                margin-bottom: 15px;
                display: flex;
                align-items: center;

                img {
                  margin-right: 3px;
                }
              }
            }
          }
          &-button {
            background-color: transparent;
          }
        }
        &_message {
          width: 100%;
          height: 20px;
          @include centering;
          background-color: $basic-color-violet;
          color: $white;
          border: 1px solid transparent;
          padding: 16px;
          font-size: 14px;
          font-weight: 400;
          border-radius: $basic-color-borderInpRad;
          margin-top: 10px;

          &:hover {
            background-color: $basic-color-text-title;
            border: 1px solid transparent;
            color: $white;
          }
        }
      }
      &-alert {
        width: 100%;
        height: 84px;
        background-color: $white;
        border: 1px solid $basic-color-borderInpPlaceholder;
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-left: 6px solid rgba(213, 26, 82, 0.3);
        position: relative;
        margin-top: 50px;
        border-radius: 4px;

        &_img {
          content: "";
          width: 40px;
          height: 40px;
          min-width: 40px;
          min-height: 40px;
          background-color: rgba(213, 26, 82, 0.3);
          z-index: 5;
          border-radius: 50%;
          @include centering;
          margin: 0 15px;
        }
        &_block {
          font-family: $font-fontDefault-Popins;
          position: relative;

          &_h3 {
            font-size: 16px;
            font-weight: 700;
            color: rgba(213, 26, 82, 1);
            margin-bottom: 5px;
          }
          &_text {
            line-height: 19px;
            max-height: 40px;
            overflow: auto;
          }
        }
      }
      &-moderation {
        margin-top: 20px;

        &_item {
          // border: 1px solid $basic-color-borderInpPlaceholder;
          box-shadow: 0px 0px 20px -5px #CCC;
          border-radius: 8px;

          &-header {
            height: 40px;

            button.accordion-button {
              height: 40px;
              background-color: transparent;
              border-radius: 0;

              &:before {
                display: none;
              }
            }
          }
          &-body {
            width: 100.5%;
            height: max-content;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            padding: 0;
            margin-top: -3px;
            margin-left: -1px;
            // border: 1px solid $basic-color-borderInpPlaceholder;
            z-index: 2;
            position: relative;

            &_checkbox {
              width: 100%;
              height: 40px;
              display: flex;
              align-items: center;
              padding: 0 12px;
              justify-content: flex-start;
              // border-bottom: 1px solid $basic-color-borderInpPlaceholder;
              border-radius: 0;
              margin: 0;

              input[type="checkbox"]:checked {
                background-color: $basic-color-violet;
                border: 3px solid $white;
                border-radius: 50%;
              }
              .form-check-input:checked {
                background-color: $basic-color-violet;
                border: 3px solid $white;
                border-radius: 50%;
                --bs-form-check-bg-image: none;
              }
              .form-check-input:checked[type="checkbox"] {
                --bs-form-check-bg-image: none;
              }
              .form-check-input {
                margin-left: 3px;
                margin-right: 5px;
                margin-bottom: 0;
                border-radius: 50%;
              }
            }
            &_block {
              width: 100%;
              max-height: 160px;
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              overflow-x: auto;
              margin: 0;
              height: 0;
              opacity: 0;
              &-wrapper {
                width: 100%;
                display: flex;
                border-bottom: 1px solid $basic-color-borderInpPlaceholder;
              }

              button {
                text-align: left !important;
                justify-content: flex-start;
                padding-left: 50px;
              }
              &-checkbox {
                width: 100%;
                height: 40px;
                min-height: 40px;
                display: flex;
                align-items: center;
                padding: 0 5px 0 30px;
                //border-bottom: 1px solid $basic-color-borderInpPlaceholder
                margin: 0;

                &:last-child {
                  border-bottom: none;
                }
                .form-check-input {
                  margin-left: 3px;
                  margin-right: 5px;
                  margin-bottom: 0;
                  border-radius: 0;
                }
                input[type="checkbox"]:checked {
                  background-color: $basic-color-violet;
                }
                &_delete {
                  background-color: transparent;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
    &_buttons {
      display: flex;
      align-items: center;
      //position: absolute
      //top: 0

      &-save {
        @include buttonCustom(200, 40, 16);
        margin-right: 10px;
      }
      &-back {
        @include buttonCustom(200, 40, 16);
        background-color: $white;
        color: $basic-color-text-title;
        border: 1px solid $basic-color-violet;
        &:focus {
          box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.8);
          &:active {
            box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.8);
            background-color: $basic-color-text-title;
            border: 1px solid transparent;
            color: $white;
          }
        }
      }
    }
  }
  &-filtered {
    margin: 20px 0;
    position: relative;

    &_wrapper {
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      border: 1px solid $basic-color-borderInpPlaceholder;
      padding: 0 10px;
      border-radius: 4px;
      margin-top: 10px;

      &-show {
        animation: AnimationShowBlockFiltered 1s ease forwards;
      }
      &-hide {
        animation: AnimationHideBlockFiltered 1s ease forwards;
      }
      &-block {
        margin: 5px 10px 0px 0;
        width: 300px;

        &_filtering {
          @include buttonCustom(80, 40, 16);
          margin-right: 10px;
          margin-top: 20px;
        }
        &_remove {
          @include buttonCustom(30, 30, 16);
          background-color: $white;
          color: $basic-color-text-title;
          border: 1px solid $basic-color-violet;
          margin-top: 30px;
        }
      }
    }
    &_title {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 5px;
    }
    &_select {
      border-radius: 4px;
      width: 100%;
      padding: 6px 12px;
      font-size: 14px;
      border: 1px solid $basic-color-borderInp;
    }
    &_button {
      background-color: $white;
      margin-right: 5px;
    }
  }
}
@keyframes AnimationShowBlockFiltered {
  to {
    transform: translateY(0);
    opacity: 1;
    position: relative;
    z-index: 2;
  }
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
}

@keyframes AnimationHideBlockFiltered {
  to {
    transform: translateY(-20px);
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  from {
    transform: translateY(0);
    opacity: 1;
  }
}

.CheckedIcon {
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(245, 243, 247, 1);
  width: fit-content;
  padding: 5px 10px;
}
.statusOne {
  border-left: 6px solid rgba(0, 160, 70, 0.3);

  &_img {
    background-color: rgba(0, 160, 70, 0.3);
  }
  &_h3 {
    color: rgba(0, 160, 70, 1);
  }
}
.statusTwo {
  border-left: 6px solid rgba(33, 70, 143, 0.3);

  &_img {
    background-color: rgba(33, 70, 143, 0.3);
  }
  &_h3 {
    color: rgba(33, 70, 143, 1);
  }
}
.statusInfo {
  border-left: 6px solid rgba(255, 142, 0, 0.3);

  &_img {
    background-color: rgba(255, 142, 0, 0.3);
  }
  &_h3 {
    color: rgba(255, 142, 0, 1);
  }
}
.noticeItem-wrapper_info-images .div-12 {
  @include centering;
  flex-direction: column;
}
.div-12-block {
  width: inherit;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.div-12-wrapper {
  display: flex;
  align-items: center;
  height: 100px;
  overflow-x: auto;
  overflow-y: hidden;
  img {
    height: 100%;
    width: 100%;
  }
}
.active .hide {
  display: none;
}
.img-12-min {
  width: 64px;
  height: 64px;
}
.div-12-max {
  width: 340px;
  height: 340px;
  border: 3px solid #fff;
  margin-bottom: 10px;
}
.img-12-max {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.active-img {
  border: 3px solid $basic-color-violet;
}
.button-primary {
  width: 40px;
  min-width: 40px;
  height: 100px;
  background-color: $white;
  color: #111;
  border-radius: 8px;
}
.activeDropdownStatus {
  animation: animationShowStatusDropdown 1s ease;
  height: 100%;
  opacity: 1;
}
@keyframes animationShowStatusDropdown {
  to {
    height: 100%;
    opacity: 1;
  }
  from {
    height: 0;
    opacity: 0;
  }
}

.add-edit-noticeItem_createRulModal {
  .modal-dialog {
    max-width: max-content !important;
  }
  .add-edit-terms_top {
    justify-content: flex-start;
    .add-edit-terms_label {
      &:nth-child(2) {
        margin: 0 10px;
      }
    }
  }
}
