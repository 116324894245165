@import "../_variables";

.categories {
  &-create {
    max-width: 583px;
    width: 100%;

    &_block {
      width: inherit;
      height: max-content;
      border: 1px solid $basic-color-borderInpPlaceholder;
      border-radius: 4px;
      padding: 20px;
      margin-bottom: 20px;
      .template-content_nameBlock-label {
        margin: 20px 0;
      }
      &-title {
        font-weight: 600;
        font-size: 16px;
      }
      &-wrapper {
        width: 100%;
      }
      &-showImage {
        min-height: 230px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 2px dashed $basic-color-borderInpPlaceholder;
        border-radius: 4px;
        margin-top: 20px;
        position: relative;
      }
      &-addImage {
        min-height: 230px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 2px dashed $basic-color-borderInpPlaceholder;
        border-radius: 4px;
        margin-top: 20px;
        position: relative;
        &_file {
          width: 100%;
          min-height: 300px;
          height: 100%;
          position: absolute;
          opacity: 0;
        }
        &_photo {
          width: 100%;
          height: 100%;
          @include centering;
          position: relative;
          &-img {
            max-height: 280px;
            max-width: 450px;
          }
          &-del {
            background-color: transparent;
            position: absolute;
            right: 20px;
          }
        }
        &_img {
          width: 78px;
          height: 78px;
          margin: 20px 0;
        }
        &_text {
          font-weight: 400;
          font-size: 16px;
        }
        &_button {
          width: max-content;
          height: 25px;
          margin: 20px 0;
        }
        &_subtext {
          font-weight: 400;
          font-size: 14px;
          margin-bottom: 6px !important;
        }
      }
      &-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 15px;
        button {
          width: max-content;
        }
      }
      &-body {
        &_list {
          max-width: inherit;
          margin-bottom: 20px;
        }
      }
    }
  }
}
.terms-wrapper_block {
  width: 100%;
  display: flex;
  .table-responsive {
    width: 100%;
    .terms-wrapper_tabl {
      width: inherit;
    }
  }
}
.loader-component_subcategory {
  min-width: 150px;
  min-height: 150px;
  width: max-content;
  height: max-content;
}
.animated-sortingCategoriesPage {
  animation: animationUnitForPriceModal1 ease 1s forwards;
}
@keyframes animationUnitForPriceModal1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
