.warpper_absolute{
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -5px;
    right: -5px;
    cursor: pointer;
    background: #FFF;
    border-radius: 50%;
    transition: .2s;

    &:hover{
        background-color: #FFE7D7;
        color: #D128A1;
        .wrapper_relative{
            .message_wrapper{
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .wrapper_relative{
        position: absolute;

        .icon{
            width: 18px;
            height: 18px;
            border: 1px #171717 solid;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateY(1px);
        }
    
        .message_wrapper{
            position: absolute;
            bottom: 150%;
            left: 50%;
            background: #FFF;
            z-index: 100;
            box-shadow: -2px 2px 10px 0px #7070754D;
            padding: 8px 16px;
            width: 220px;
            transform: translateX(-50%);
            visibility: hidden;
            opacity: 0;
            transition: .2s;

            .message{
                font-size: 12px;
                color: #171717;
                white-space: wrap;
            }

            .arrow{
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid #FFF;
                position: absolute;
                transform: translateX(-50%);
                top: 100%;
                left: 50%;
            }
        }
    }
}