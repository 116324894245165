.validation{
    font-size: 12px;
    margin-left: 20px;
    color: #ff6d6d;
    svg{
      transform: translateY(-2px);
    }
  }
  .validation_link{
    color:#FF6600;
    cursor: pointer;
  }