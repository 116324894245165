.content{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  h3{
    margin-bottom: 5px;
  }
  textarea{
    width: 700px;
    height: 300px;
    border: 1px #E5E0EB solid;
    padding: 8px 12px;
  }
  .error{
    //border: 1px red solid;
    outline: 1px red solid;
  }
  button{
    opacity: 0.5;
  }
}