.wrapper{
    width: 100%;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0px 0px 20px -5px  #CCC;
    margin-top: 20px;
    
    .items_wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 30px 0;

        .item_wrapper{
            padding: 15px;
            box-shadow: 0px 0px 3px 1px #CCC;
            width: 100%;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            position: relative;

            .icons_wrapper{
                position: absolute;
                top: 10px;
                right: 10px;
                display: flex;
                gap: 10px;
                img{
                    cursor: all-scroll;
                    width: 20px;
                    height: 20px;
                    user-select: none;
                }
                svg{
                    cursor: pointer;
                    width: 20px;
                    height: 20px;
                }
            }

            .header{
                display: flex;
                gap: 20px;

                select{
                    border-radius: 4px;
                    width: fit-content;
                    padding: 10px;
                    background: #DEDBF5;
                    height: fit-content;
                }

                .center{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
    
                    input{
                        border: 1px #CCC solid;
                        border-radius: 4px;
                        padding: 5px 7px;
                    }
                }
            }

            .select_options{
                background: #ececec;
                padding: 15px;
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .options_wrapper{
                    width: fit-content;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 15px;
                    
                    .option_wrapper{
                        border: 1px #CCC solid;
                        border-radius: 4px;
                        padding: 30px 15px 15px 15px;
                        background: #DEDBF5;
                        position: relative;
                        .delete_icon_wrapper{
                            position: absolute;
                            top: 5px;
                            right: 5px;
                            cursor: pointer;
                        }
                    }
                }

                .footer{
                    display: flex;
                    gap: 10px;
                    input{
                        border-radius: 4px;
                        padding: 0 7px;
                    }

                    button{
                        width: fit-content;
                    }
                }
            }
        }
    }

    .image_type_wrapper{
        select{
            padding: 3px 7px;
            border-radius: 4px;
            width: fit-content;
            padding: 5px 7px;
        }
    }
}