.error{
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    svg{
        max-width: 90%;
    }
    h2{
        text-align: center;
        max-width: 90%;
    }
}