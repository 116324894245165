.terms{
    min-height: 500px;
    .terms_wrapper{
        position: relative;
        height: 100%;
        h2{
            color: #FF6600;
        }
        .header{
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-bottom: 20px;
            input{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                visibility: hidden;
            }
            button{
                white-space: nowrap;
                width: fit-content;
            }
            .back_button{
                position: absolute;
                top: 0;
                left: 15px;
                display: flex;
                gap: 10px;
                width: fit-content;
                svg{
                    flex-shrink: 0;
                    transform: rotate(180deg);
                    stroke: #FFF !important;
                    fill: #FFF !important;
                    path{
                        stroke: #FFF !important;
                        fill: #FFF !important;
                    }
                }
            }
        }
        .new_files_images_wrapper{
            background-color: #F8F7FD;
            padding: 30px 0 20px 0;
            margin-bottom: 50px;
            padding: 10px 20px;
            .content{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                row-gap: 30px;
                min-height: 500px;
                flex-wrap: wrap;
            }
            .footer{
                display: flex;
                justify-content: center;
                position: relative;
                width: 100%;
                margin-top: 20px;
                button{
                    white-space: nowrap;
                    width: fit-content;
                }
            }
        }
        .media_wrapper{
            min-height: 500px;
            flex-wrap: wrap;
            .content{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                row-gap: 30px;
                min-height: 500px;
                flex-wrap: wrap;
            }
        }
        .image_wrapper{
            width: calc(25% - 10px);
            border: 1px #CCC solid;
            border-radius: 8px;
            position: relative;
            .header{
                padding: 5px 10px;
                background-color: #F8F7FD;
                border-radius: 8px 8px 0 0;
            }
            .image{
                width: 100%;
                height: 300px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center;
                }
            }
            .tools_wrapper{
                position: absolute;
                top: 0px;
                right: 0px;
                border-radius: 0px 8px 0px 8px;
                border: 1px #FF6600 solid;
                background-color: #FF6600;
                padding: 7px;
                cursor: pointer;
                opacity: 0;
                transition: .3s;
                svg{
                    path{
                        fill: #FFF !important;
                    }
                }
                &:hover{
                    background-color: #000;
                    border: 1px #000 solid;
                }
            }
            .link_wrapper{
                cursor: pointer;
            }
            .delete_wrapper{
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                cursor: pointer;
            }
            &:hover{
                .tools_wrapper{
                    opacity: 1;
                }
            }
        }
        .loader_wrapper{
            width: 100%;
            height: 100%;
            min-height: 500px;
            position: absolute;
            top: 0;
            left: 0;
            backdrop-filter: blur(15px);
            svg{
                position: absolute;
                left: 50%;
                top: 300px;
                path{
                    fill: #FF6600 !important;
                }
            }
        }
    }
    .pagination{
        display: flex;
        justify-content: space-between;
    }
}