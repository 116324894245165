.confirm_wrapper{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(15px);
    z-index: 200;

    .confirm_modal{
        width: fit-content;
        z-index: 200;
        background-color: #fff;
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        animation: show_modal .5s forwards;
        box-shadow: 0px 0px 10px 2px #ccc;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title{
            font-weight: 600;
            text-align: center;
            font-style: 20px;
        }
        .buttons{
            display: flex;
            gap: 15px;
        }
    }
}
