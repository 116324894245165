.modal_wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;

    .modal{
        width: 90%;
        max-width: 700px;
        height: fit-content;
        background: #FFF;
        box-shadow: 0 0 35px #3e134d26;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .title{
            font-size: 32px;
            font-weight: 700;
            color: #FF6600;
        }

        .content{
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 30px;
        }

        .type_wrapper{
            display: flex;
            gap: 10px;
            align-items: center;
        }

        .footer{
            display: flex;
        }
    }
}