.item{
    padding: 4px 8px;
}
.status{
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding: 4px 8px;
    font-weight: 400;
    white-space: nowrap;
    border-radius: 2px;
}
.status_active{
    background-color: rgb(0, 160, 70);
}
.status_not_active{
    background-color: rgb(248, 65, 70);
}
.icons_td{
    width: 100px !important;
}
.icons_row{
    display: flex;
    justify-content: flex-end;
    width: fit-content;

    .icons_wrapper{
        display: flex;
        gap: 10px;
        svg{
            cursor: pointer;
        }
    }
}
.modal_td{
    position: static !important;
    .modal_td_wrapper{
        svg{
            cursor: pointer;
        }
        .modal{
            opacity: 0;
            visibility: hidden;
            transition: .3s;
        }
        &:hover{
            .modal{
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
