@import "../_variables";
.header {
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  padding: 0 10px;
  padding-left: 220px;

  @include tablet {
    height: 72px;
  }

  .accordion {
    --bs-accordion-border-color: transparent;
    --bs-accordion-active-bg: transparent;
    --bs-accordion-btn-focus-border-color: 0;
    --bs-accordion-btn-focus-box-shadow: 0;
  }

  &-navInfo {
    display: flex;
    align-items: center;
    column-gap: 8px;

    .header-user {
      &_block {
        display: flex;
        align-items: center;
        border-left: 1px solid $header-verticalLine;
      }
      &_img {
        width: 36px;
        height: 36px;
        margin: 0 8px 0 16px;
      }
      &_wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        position: relative;
      }
      &_name {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
      }
      &_status {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        color: $header-color-status;
      }
      &_arrow {
        position: absolute;
        right: 0;
      }
    }
    &_burger {
      background-color: #fff;
      padding: 10px;
      transition: all 0.3s ease;
      display: none;

      &:hover {
        transform: scale(1.1);
      }

      @include tabletMax {
        display: block;
      }
    }
  }

  &__menu {
    background-color: #fff;
    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
    z-index: 9999;
    padding: 0 36px;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    overflow-y: scroll;

    @include tabletMax {
      padding: 0 24px;
      height: calc(100vh - 90px);
    }

    @include tablet {
      top: 72px;
      height: calc(100vh - 72px);
    }
    @include mobile {
      padding: 0 16px;
    }

    &_translate {
      transform: translateX(0);
    }
  }
}
