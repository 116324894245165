@import "../_variables";
.alertModal {
  width: 368px;
  height: 100px;
  position: fixed;
  right: 30px;
  top: 50%;
  background-color: $white;
  border: 1px solid $basic-color-borderInp;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-left: 6px solid rgba(238, 218, 190, 1);
  //opacity: 0.2
  &-block {
    margin-left: 19px;
    font-family: $font-fontDefault-Popins;
    position: relative;
    display: flex;
    flex-direction: column;
    &_h3 {
      font-size: 16px;
      font-weight: 700;
      color: #ff9500;
      margin-bottom: 0;
    }
    &_text {
      width: 254px;
      white-space: pre-wrap !important;
    }
    &_close {
      position: absolute;
      right: -20px;
      top: -20px;
      cursor: pointer;
    }
  }
}
.alertProjeck,
.alertProjeck-successfully {
  width: 50%;
  height: auto;
  position: fixed;
  right: 0;
  top: 0;
  background-color: $white;
  border: 1px solid $basic-color-borderInp;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-left: 6px solid rgba(238, 218, 190, 1);

  @include tablet {
    width: 100%;
  }
  //opacity: 0.2
  &-block {
    margin-left: 19px;
    font-family: $font-fontDefault-Popins;
    position: relative;

    &_h3 {
      font-size: 16px;
      font-weight: 700;
      color: #ff9500;
      margin-bottom: 0;
    }
    &_text .alertProjeck-successfully {
      border-left: 6px solid rgba(#00b998, 1);
    }
  }
}
.alertProjeck-successfully-block_h3 {
  color: #00b998;
}
