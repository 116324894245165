@import "../_variables";

.faq {
  &-wrapper {
    &_content {
      margin-top: 30px;
      .accordion {
        --bs-accordion-border-color: transparent;
        --bs-accordion-active-bg: transparent;
        --bs-accordion-btn-focus-border-color: 0;
        --bs-accordion-btn-focus-box-shadow: 0;
        &-body {
          .accordion {
            --bs-accordion-btn-focus-border-color: 0;
            --bs-accordion-btn-focus-box-shadow: 0;
          }
        }
      }
      .accordion-header {
        button {
          position: relative;
        }
      }
      .accordion-button::after {
        position: absolute;
        left: 0;
      }
      span {
        margin-left: 15px;
      }
      &-buttonEdit {
        width: max-content;
        border-radius: 4px;
        padding: 8px 12px;
        position: absolute !important;
        right: 0;
      }
      &-acordingBody {
        max-height: 600px;
        overflow: auto;
      }
    }
  }
}
