.table{
    width: 100%;
    border-collapse: collapse;

    thead{
        border-top: 1px solid #c2c2c2;
        border-bottom: 1px solid #c2c2c2;
    }
    tr{
        td{
            padding: 12px 5px;
        }
    }
    
    .amount{
        color: #FF6600;
        font-size: 14px;
        font-weight: 500;
    }

    .completed{
        background: #38C63D;
        color: #FFF;
        border-radius: 2px;
        padding: 4px 8px;
        font-size: 14px;
    }
    .wait{
        background: #E7BE2F;
        color: #FFF;
        border-radius: 2px;
        padding: 4px 8px;
        font-size: 14px;
    }
}